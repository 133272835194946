import React from "react";
import axios from "axios";
import { B2B_PORTAL_JWT_SECRET_KEY, B2B_PORTAL_CSRF_TOKEN } from "redmond";
import { useCorpRedirects } from "@capone/common";
import { createRequestLog, createResponseLog } from "../Logger";
import config from "../../utils/config";

interface IAxiosInterceptors {
  children?: React.ReactNode;
}

export const mcLeanAxiosInstance = axios.create();

const AxiosInterceptors = ({ children }: IAxiosInterceptors) => {
  useCorpRedirects(mcLeanAxiosInstance, config.TENANT);
  const getCookie = (name: string) => {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
    else return undefined;
  };

  const getCsrfToken = () => getCookie(B2B_PORTAL_CSRF_TOKEN);

  React.useEffect(() => {
    const mcleanResponseInterceptor =
      mcLeanAxiosInstance.interceptors.response.use(
        (res) => {
          createResponseLog(res);
          return res;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

    const mcleanRequestInterceptor =
      mcLeanAxiosInstance.interceptors.request.use(
        (req) => {
          const csrfToken = getCsrfToken();

          //TODO: remove GCP token
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              B2B_PORTAL_JWT_SECRET_KEY
            )}`,
            ...(csrfToken && { "H-Csrf-Token": csrfToken }),
          };

          req.headers.set({ ...req.headers, ...headers });

          createRequestLog(req);
          return req;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

    //runs when component unmount
    return () => {
      mcLeanAxiosInstance.interceptors.request.eject(mcleanRequestInterceptor);
      mcLeanAxiosInstance.interceptors.response.eject(
        mcleanResponseInterceptor
      );
    };
  }, []);

  return <>{children}</>;
};

export default AxiosInterceptors;
