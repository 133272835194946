import React from "react";
import { BasePage } from "./BasePage";
import Warning from "./assets/warning.svg";
import { ActionButton } from "halifax";

export const AccessDeniedNotInBeta = () => {
  return (
    <BasePage
      icon={<img src={Warning} alt="Warning Icon" />}
      title="Access Denied"
      description={
        <>
          Oh no, it looks like you don’t have access to Capital One Business
          Travel quite yet.
        </>
      }
      buttons={[
        <ActionButton
          key="go-to-travel"
          defaultStyle="h4r-primary"
          onClick={() => {
            window.location.href = "http://travel.capitalone.com/";
          }}
          message="Go to Capital One Travel"
        />,
      ]}
    />
  );
};
