import axios from "axios";
import { WalletHistoryResponse } from "redmond";
import { config } from "../../config";
import { travelWalletApiPrefix } from "../../paths";

const END_POINT: string = `${travelWalletApiPrefix}/history`;

export const fetchTravelWalletCreditHistory =
  (): Promise<WalletHistoryResponse> =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(END_POINT, {
          baseURL: config.baseURL,
        });
        resolve(res.data);
      } catch (e) {
        reject(e);
      }
    });
