import React, { useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core";
import { ExperimentState, TermsModuleProps } from "redmond";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import clsx from "clsx";
import { useUserContext, isCorpTenant } from "@capone/common";

import { PAWTUCKET_TERMS_MODULE_ID } from "../../utils/moduleIds";
import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { colors as caponeColors } from "../../utils/capone/colors";
import { colors as corpColors } from "../../utils/capone-corporate/colors";
import "./styles.scss";

interface ITermsModuleProps extends RouteComponentProps {
  language: string;
  experiments?: ExperimentState;
  authenticated?: boolean;
}

const TermsModule = (props: ITermsModuleProps) => {
  const { language, experiments, ...routerProps } = props;
  const { history, authenticated } = routerProps;
  const id = PAWTUCKET_TERMS_MODULE_ID;
  const theme = useTheme();
  const { matchesDesktop, matchesSmallDesktop } = useDeviceTypes();
  const { isLoaded, ptTermsModule } = useMicrofrontend(
    id,
    (config.ptTermsModuleUrl += "")
  );
  const { sessionInfo, logo } = useUserContext(config.TENANT);

  useLayoutEffect(() => {
    if (!ptTermsModule) return;
    const { render, unMount } = ptTermsModule;
    const termsModuleProps: TermsModuleProps = {
      theme,
      language: language,
      colors: isCorpTenant(config.TENANT) ? corpColors : caponeColors,
      baseHistory: history,
      experiments,
      clientAssets: {
        logo: <img src={logo?.src} alt={logo?.alt} />,
        sessionInfo,
      },
    };
    render(id, termsModuleProps);
    return () => {
      unMount(id);
    };
  }, [isLoaded]); // eslint-disable-line

  if (!isLoaded) return null;
  if (!ptTermsModule) return <h2>ERROR! Cannot find Module</h2>;

  return (
    <div
      className={clsx("terms-module-container", config.TENANT, {
        desktop: matchesDesktop || matchesSmallDesktop,
        authenticated: authenticated, //  Note: uses 'authenticated' className to create more margin before displaying terms content because authenticated header is taller than not authenticated
      })}
      id={id}
    />
  );
};

export default TermsModule;
