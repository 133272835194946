import React from "react";
import { useDeviceTypes } from "halifax";

import MobileHeader from "./components/MobileHeader";
import DesktopHeader from "./components/DesktopHeader";
import { RewardsAccount } from "redmond";
import { CorpBusinessAccount } from "redmond/apis/tysons/businesses";

interface IHeaderProps {
  language: string;
  locationPath: string;
  rewardsAccounts: RewardsAccount[];
  selectedRewardsAccountId: string | null;
  setSelectedRewardsAccountId: (id: string | null) => void;
  notAuthenticated?: boolean;
  canViewConsumer: boolean;
  corpBusinessAccounts: CorpBusinessAccount[];
  className?: string;
}

const CorpHeader = (props: IHeaderProps) => {
  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? (
    <MobileHeader {...props} />
  ) : (
    <DesktopHeader {...props} />
  );
};

export default CorpHeader;
