import dayjs from "dayjs";
import {
  FlightItineraryResponse,
  FlightItineraryState,
  BookedFlightItineraryWithDepartureTime,
  getDepartureSlice,
  getReturnSlice,
  HotelItineraryResponse,
  HotelItineraryState,
  ILocationQueryLabel,
  LocationQueryEnum,
  IResponse,
  IResult,
  RewardsAccount,
  ActiveCrossSellOffersResponse,
  IIdLodgings,
} from "redmond";
import { fetchFlights } from "../../api/v1/cross-sell/fetchFlights";
import { fetchHotels } from "../../api/v1/cross-sell/fetchHotels";
import { fetchLodgingLocationAutocomplete } from "../../api/v1/cross-sell/fetchLodgingLocationAutocomplete";
import { fetchCrossSellActiveOffers } from "../../api/v1/cross-sell/fetchCrossSellActiveOffers";

export interface CrossSellProps {
  searchLocationResult: IResult | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultCount: number;
  children: number[];
  largestValueAccount: RewardsAccount;
  latency: number;
  bannerText?: string;
  tysonsOffer: boolean;
}
export const fetchCrossSellInfoV1 = async (
  largestValueAccount: RewardsAccount
): Promise<CrossSellProps | false> => {
  try {
    const startTime = dayjs();
    const flightItinerariesResponse: FlightItineraryResponse =
      await fetchFlights({
        states: [FlightItineraryState.Future],
        referenceDateTime: dayjs().format(),
      });
    const { airports, itineraries } = flightItinerariesResponse;
    const { future } = itineraries;
    const sortItinerariesByDate = (
      a: BookedFlightItineraryWithDepartureTime,
      b: BookedFlightItineraryWithDepartureTime
    ) => {
      let aDate = getDepartureSlice(a.bookedItinerary).segments[0]
        .scheduledDeparture;
      let bDate = getDepartureSlice(b.bookedItinerary).segments[0]
        .scheduledDeparture;

      const getDepDiff = (dateStringX?: string, dateStringY?: string) => {
        const aDiff = dayjs(dateStringX).diff(dayjs());
        const bDiff = dayjs(dateStringY).diff(dayjs());
        return aDiff - bDiff;
      };

      const depDiff = getDepDiff(aDate, bDate);
      if (depDiff === 0) {
        let aReturn =
          getReturnSlice(a.bookedItinerary)?.segments[0].scheduledArrival || "";
        let bReturn = getReturnSlice(b.bookedItinerary)?.segments[0]
          .scheduledArrival;
        aDate = aReturn || aDate;
        bDate = bReturn || bDate;
        return getDepDiff(aDate, bDate);
      }
      return depDiff;
    };
    if (!future) {
      return false;
    }

    const sortedUpcomingFlights = future
      ? future.sort((a, b) => sortItinerariesByDate(a, b))
      : [];
    const bookedItinerary = sortedUpcomingFlights[0].bookedItinerary;
    const departureSlice = getDepartureSlice(bookedItinerary as any);
    // Gets the last segment to avoid using the layover destinationCode
    const departureLastSegmentIndex = departureSlice
      ? departureSlice.segments.length - 1
      : 0;
    const departureDestinationSegment =
      departureSlice.segments[departureLastSegmentIndex];

    const departureDate = new Date(
      departureDestinationSegment.zonedScheduledArrival ??
        departureDestinationSegment.updatedArrival
    );
    const departureDateWithoutTime = dayjs(departureDate).format("YYYY-MM-DD");
    // Since the checkin date does not have time, it's best to compare dates without time in the .find() below (incase departure time is later than the default 12AM GMT)

    const destinationCode =
      departureDestinationSegment?.destination.locationCode ?? "";
    const destination = airports[destinationCode]?.cityName || destinationCode;

    const returnSlice = getReturnSlice(bookedItinerary as any);

    const returnDate = new Date(
      returnSlice?.segments[0].zonedScheduledArrival ??
        returnSlice?.segments[0].updatedArrival ??
        dayjs(departureDate).add(1, "day").toDate() // if one-way trip, look for hotel for 1 night
    );

    const returnDateWithoutTime = dayjs(returnDate).format("YYYY-MM-DD");
    const adultPaxCount = [
      ...bookedItinerary.passengers.alone,
      ...bookedItinerary.passengers.withLapInfants.map((p) => p.adult),
    ].filter((p) => (p.type as any) === "ADT").length;
    const childPaxArr = bookedItinerary.passengers.alone.filter(
      (p) => (p.type as any) !== "ADT"
    );

    const hotels: HotelItineraryResponse = await fetchHotels({
      states: [HotelItineraryState.Present, HotelItineraryState.Future], // Using departure date, look for present AND future (checkin date same as departure date or after)
      referenceDateTime: departureDate.toISOString(),
    });

    const { present: presentHotels = [], future: futureHotels = [] } =
      hotels.itineraries;

    const bookedHotel = [...presentHotels, ...futureHotels].find(
      (itinerary) => {
        const checkinDate = new Date(itinerary.reservation.checkInDate); // Defaults to 12AM GMT
        const formattedDepartureDateWithoutTime = new Date( // If we use normal departure date, if flight lands 1AM instead of 12AM, hotel would not be in range.
          departureDateWithoutTime
        );
        const formattedReturnDateWithoutTime = returnSlice
          ? new Date(returnDateWithoutTime)
          : new Date(dayjs(departureDateWithoutTime).add(2, "day").toDate()); // if one-way trip, look for hotel booking made for within 2 days of departure
        return (
          checkinDate.valueOf() >=
            formattedDepartureDateWithoutTime.valueOf() &&
          checkinDate.valueOf() <= formattedReturnDateWithoutTime.valueOf()
        );
      }
    );

    if (!bookedHotel) {
      const fromDate = departureDate;
      const untilDate = returnDate;
      let allLocations: IResult[] = [];
      if (airports[destinationCode]?.geography.countryCode) {
        const locationRequestWithCountryCodeBody: ILocationQueryLabel = {
          LocationQuery: LocationQueryEnum.Label,
          l: `${destination}, ${airports[destinationCode].geography.countryCode}`,
        };
        const { categories: locationWithCountryCodeCategories }: IResponse =
          await fetchLodgingLocationAutocomplete(
            locationRequestWithCountryCodeBody
          );

        allLocations.push(
          ...locationWithCountryCodeCategories.flatMap(
            (category) => category.results
          )
        );
      }

      const locationRequestBody: ILocationQueryLabel = {
        LocationQuery: LocationQueryEnum.Label,
        l: destination,
      };
      const { categories: locationCategories }: IResponse =
        await fetchLodgingLocationAutocomplete(locationRequestBody);

      const nonCountryCodeLocations = locationCategories.flatMap(
        (category) => category.results
      );
      allLocations.push(...nonCountryCodeLocations);
      const matchingLocation =
        allLocations.find(
          (result) =>
            result.label.toLowerCase() === destination.toLowerCase() ||
            result.label
              .toLowerCase()
              .startsWith(`${destination.toLowerCase()},`)
        ) ||
        allLocations.find((result) =>
          result.label.toLowerCase().includes(destination.toLowerCase())
        );

      const correspondingLocation =
        matchingLocation || nonCountryCodeLocations[0];
      const adultCount = adultPaxCount;
      const searchLocationResult = correspondingLocation;
      return {
        searchLocationResult: searchLocationResult || null,
        fromDate,
        untilDate,
        adultCount,
        children: childPaxArr.map((child) =>
          dayjs().diff(child.person.dateOfBirth, "years")
        ),
        largestValueAccount,
        latency: dayjs().diff(startTime, "seconds", true),
        tysonsOffer: false,
      };
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const fetchCrossSellInfoV3 = async (
  largestValueAccount: RewardsAccount,
  isHotelCrossSellV3Enabled?: boolean
): Promise<CrossSellProps | false> => {
  try {
    if (!isHotelCrossSellV3Enabled) {
      return fetchCrossSellInfoV1(largestValueAccount);
    }
    const startTime = dayjs();
    const crossSellActiveOffersResponse: ActiveCrossSellOffersResponse =
      await fetchCrossSellActiveOffers({});
    const { activeOffers } = crossSellActiveOffersResponse;
    if (activeOffers.length > 0) {
      // Take first active offer
      const firstActiveOffer = activeOffers[0];
      const firstActiveOfferDestination = firstActiveOffer.destinationDetail;
      if (firstActiveOfferDestination.lodgingSelection) {
        return {
          searchLocationResult: {
            id: {
              lodgingSelection: firstActiveOfferDestination.lodgingSelection,
            } as IIdLodgings,
            label:
              // TODO: should be using cityName but currently that's coming back as Miami instead of Miami, FL, USA
              firstActiveOfferDestination.lodgingSelection["searchTerm"] ??
              firstActiveOfferDestination.cityName,
          },
          fromDate: new Date(
            firstActiveOfferDestination.stayDates.from.replace(/-/g, "/")
          ), // If using `YYYY-MM-DD` format, it gives us prev date: https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
          untilDate: new Date(
            firstActiveOfferDestination.stayDates.until.replace(/-/g, "/")
          ),
          adultCount: firstActiveOfferDestination.guests.adults,
          children: firstActiveOfferDestination.guests.children,
          largestValueAccount,
          latency: dayjs().diff(startTime, "seconds", true),
          bannerText: firstActiveOffer.description,
          tysonsOffer: true,
        };
      }
    }
    return fetchCrossSellInfoV1(largestValueAccount);
  } catch (e) {
    console.error(e);
    return false;
  }
};
