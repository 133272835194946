import React, { useEffect, useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core";
import { ExperimentState, CustomerProfileModuleProps } from "redmond";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";

import CapOneLogo from "../../assets/b2b/capone-logo.svg";
import { PAWTUCKET_CUSTOMER_PROFILE_MODULE_ID } from "../../utils/moduleIds";
import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { colors } from "../../utils/capone/colors";
import clsx from "clsx";
import { useUserContext } from "@capone/common";
import { CAP_ONE_LOGO_ALT } from "../../utils/constants";
import {
  useExperiments,
  CUSTOMER_PROFILE_EXPERIMENT,
  CONTROL,
  getExperimentVariantCustomVariants,
  CUSTOMER_PROFILE_VARIANTS,
} from "../../context/experiments";
import { PATH_HOTELS } from "../../utils/urlPaths";

interface ICustomerProfileModuleProps extends RouteComponentProps {
  language: string;
  experiments?: ExperimentState;
}

const CustomerProfileModule = (props: ICustomerProfileModuleProps) => {
  const { language, experiments, ...routerProps } = props;
  const { history } = routerProps;
  const id = PAWTUCKET_CUSTOMER_PROFILE_MODULE_ID;
  const theme = useTheme();
  const { matchesDesktop, matchesSmallDesktop } = useDeviceTypes();
  const { isLoaded, ptCustomerProfileModule } = useMicrofrontend(
    id,
    (config.ptCustomerProfileModuleUrl += "")
  );
  const { sessionInfo } = useUserContext(config.TENANT);

  const expState = useExperiments();
  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  useEffect(() => {
    if (experiments?.experiments.length && !isCustomerProfileExperiment) {
      history.push(PATH_HOTELS);
    }
  }, [experiments]);

  useLayoutEffect(() => {
    if (!ptCustomerProfileModule) return;
    const { render, unMount } = ptCustomerProfileModule;
    const customerProfileModuleProps: CustomerProfileModuleProps = {
      theme,
      language: language,
      colors: colors,
      baseHistory: history,
      isAgentPortal: config.ptIsAgentPortal,
      clientAssets: {
        logo: <img src={CapOneLogo} alt={CAP_ONE_LOGO_ALT} />,
        sessionInfo,
      },
      experiments,
    };

    render(id, customerProfileModuleProps);

    return () => unMount(id);
  }, [isLoaded]); // eslint-disable-line

  if (!isLoaded) return null;
  if (!ptCustomerProfileModule) return <h2>ERROR! Cannot find Module</h2>;
  return (
    <div
      className={clsx("customer-profile-module-container", {
        desktop: matchesDesktop || matchesSmallDesktop,
      })}
      id={id}
    />
  );
};

export default CustomerProfileModule;
