import React, { useContext, useEffect, useState } from "react";
import {
  ButtonWrap,
  HamburgerButton,
  Header as GenericHeader,
  Icon,
  IconName,
} from "halifax";

import CorporateLogo from "../../../../assets/b2b/capone-corporate-logo.svg";
import DBCCorporateLogo from "../../../../assets/b2b/capone-corporate-logo-dbc.svg";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import {
  PATH_FLIGHTS,
  PATH_FLIGHTS_BOOK_CONFIRMATION,
  PATH_HOTELS,
  PATH_HOTELS_BOOK_CONFIRMATION,
  PATH_CARS,
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS_SEARCH,
  PATH_CARS_SEARCH,
  PATH_PREMIER_COLLECTION_SEARCH,
  PATH_CARS_BOOK_CONFIRMATION,
  PATH_HOME,
  PATH_TRAVEL_WALLET,
  PATH_PREMIER_VACATION_RENTALS_SEARCH,
} from "../../../../utils/urlPaths";

import MobileHamburgerMenu from "../../../MobileHamburgerMenu";
import clsx from "clsx";
import { Badge, Box } from "@material-ui/core";
import { fetchTravelWalletOffers } from "../../../../api/v1/takeovers/fetchTravelWalletOffers";
import {
  CorpSessionInfo,
  WalletSummaryResponse,
  CorpBusinessAccount,
} from "redmond";
import { useExperimentsById } from "@capone/experiments";
import {
  AVAILABLE,
  EXPERIENCES_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  PREMIER_COLLECTION_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import config from "../../../../utils/capone-corporate/config";
import { CorpUserContext } from "@capone/common";
import { CAP_ONE_CORP_LOGO_ALT } from "../../../../utils/constants";
import {
  CorpMultiAccountSwitcherModal,
  HAS_CHOSEN_ACCOUNT,
} from "../../MultiAccount/CorpMultiAccountSwitcherModal/component";
import { MULTI_ACCOUNT_MODAL_TITLE } from "./constants";
import { MaintenanceBanner } from "../../Maintenance/MaintenanceBanner";
import "./styles.scss";

interface IMobileHeaderProps {
  language: string;
  canViewConsumer: boolean;
  corpBusinessAccounts?: CorpBusinessAccount[];
  className?: string;
}

const MobileHeader = (_props: IMobileHeaderProps) => {
  const { className } = _props;
  const [showMenu, setShowMenu] = React.useState(false);
  const [offerCount, setOfferCount] = React.useState(0);

  const { canViewConsumer, corpBusinessAccounts } = _props;
  const { sessionInfo } = useContext(CorpUserContext);

  const maintenanceExperiment = useExperimentsById("corp-traveler-maintenance");
  const isMaintenaceBannerActive =
    Boolean(maintenanceExperiment) &&
    maintenanceExperiment?.variant === "banner";

  const expState = useExperiments();
  const isTravelWalletOfferExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_OFFER_EXPERIMENT
    ) === AVAILABLE;

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const isExperiencesExperiment =
    getExperimentVariant(expState.experiments, EXPERIENCES_EXPERIMENT) ===
    AVAILABLE;

  const multiAccountExperiment =
    useExperimentsById("corp-multi-account-fe")?.variant === "available";

  const [showCorpAccountPickModal, setShowCorpAccountPickModal] =
    useState(false);

  const handleCloseCorpAccountPickModal = () => {
    sessionStorage.setItem(HAS_CHOSEN_ACCOUNT, "true");

    setShowCorpAccountPickModal(false);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem(HAS_CHOSEN_ACCOUNT) != "true" &&
      multiAccountExperiment &&
      corpBusinessAccounts &&
      corpBusinessAccounts?.length > 1
    ) {
      setShowCorpAccountPickModal(true);
    }
  }, [corpBusinessAccounts, multiAccountExperiment]);

  useEffect(() => {
    fetchTravelWalletOffers().then((response) => {
      const hasCredit = !!(response as WalletSummaryResponse).credit;
      const offerCount = (response as WalletSummaryResponse).offerCount || 0;
      const totalCount = hasCredit ? offerCount + 1 : offerCount;
      setOfferCount(totalCount);
    });
  }, []);

  const canViewAdmin = sessionInfo?.corporateInfo.permissions?.canViewAdmin;

  const hamburger = isTravelWalletOfferExperiment ? (
    <Badge badgeContent={offerCount} className="offer-count">
      <HamburgerButton
        onOpen={() => setShowMenu(true)}
        onClose={() => setShowMenu(false)}
        open={showMenu}
        menu={
          <MobileHamburgerMenu
            onClose={() => setShowMenu(false)}
            offerCount={offerCount}
            isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
            isPremierCollectionEnabled={isPremierCollectionEnabled}
            tenant="capone-corporate"
            consumerPortalUrl={canViewConsumer ? config.consumerPortalUrl : ""}
            adminPortalUrl={canViewAdmin ? config.adminPortalUrlWithSource : ""}
            isExperiencesExperiment={isExperiencesExperiment}
          />
        }
      />
    </Badge>
  ) : (
    <HamburgerButton
      onOpen={() => setShowMenu(true)}
      onClose={() => setShowMenu(false)}
      open={showMenu}
      menu={
        <MobileHamburgerMenu
          onClose={() => setShowMenu(false)}
          tenant="capone-corporate"
          consumerPortalUrl={canViewConsumer ? config.consumerPortalUrl : ""}
          adminPortalUrl={canViewAdmin ? config.adminPortalUrlWithSource : ""}
          isExperiencesExperiment={isExperiencesExperiment}
        />
      }
    />
  );
  const showDBCLogo =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const renderGenericHeader = (
    isConfirmation: boolean,
    browserRouterProps: RouteComponentProps
  ) => (
    <>
      <GenericHeader
        isMobile={true}
        shadow={className === "homepage"}
        className={clsx("b2b", "corp", className, {
          confirmation: isConfirmation,
          "redesign-v2":
            mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2,
        })}
        middle={
          isMaintenaceBannerActive && (
            // Negative margin to undo the padding on mobile b2b-header
            <div style={{ margin: "0 -1rem" }}>
              <MaintenanceBanner
                title={maintenanceExperiment.data.title}
                subtext={maintenanceExperiment.data.subtext}
                severity={maintenanceExperiment.data.severity}
              />
            </div>
          )
        }
        left={
          <ButtonWrap
            onClick={() => browserRouterProps.history.push(PATH_HOME)}
          >
            <Box className="logo-container">
              <img
                className="mobile-logo"
                src={showDBCLogo ? DBCCorporateLogo : CorporateLogo}
                alt={CAP_ONE_CORP_LOGO_ALT}
              />
            </Box>
          </ButtonWrap>
        }
        right={hamburger}
      />
      <CorpMultiAccountSwitcherModal
        open={showCorpAccountPickModal}
        onClose={handleCloseCorpAccountPickModal}
        headerImage={
          <Icon
            className="corp-account-picker-icon-mobile"
            name={IconName.MultiAccountCards}
          />
        }
        title={MULTI_ACCOUNT_MODAL_TITLE}
        accounts={corpBusinessAccounts ?? []}
        sessionInfo={sessionInfo as CorpSessionInfo}
      />
    </>
  );

  return (
    <Switch>
      <Route
        path={[PATH_FLIGHTS, PATH_HOTELS, PATH_CARS, PATH_TRAVEL_WALLET]}
        render={(browserRouterProps: RouteComponentProps) =>
          renderGenericHeader(false, browserRouterProps)
        }
        exact
      />
      <Route
        path={[
          PATH_FLIGHTS_BOOK_CONFIRMATION,
          PATH_HOTELS_BOOK_CONFIRMATION,
          PATH_CARS_BOOK_CONFIRMATION,
        ]}
        render={(browserRouterProps: RouteComponentProps) =>
          renderGenericHeader(true, browserRouterProps)
        }
        exact
      />
      <Route
        path={PATH_FLIGHTS_SEARCH}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps)
            : null
        }
      />
      <Route
        path={PATH_HOTELS_SEARCH}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps)
            : null
        }
      />
      <Route
        path={PATH_CARS_SEARCH}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps)
            : null
        }
      />
      <Route
        path={[
          PATH_PREMIER_COLLECTION_SEARCH,
          PATH_PREMIER_VACATION_RENTALS_SEARCH,
        ]}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps)
            : null
        }
      />

      <Route
        path="*"
        render={(browserRouterProps: RouteComponentProps) =>
          renderGenericHeader(false, browserRouterProps)
        }
      />
    </Switch>
  );
};

export default MobileHeader;
