import axios from "axios";
import { config } from "../../config";
import { userHotelPreferencesPath } from "../../paths";
import { UserHotelPreferencesPayload } from "redmond";

export const fetchUserHotelPreferences =
  (): Promise<UserHotelPreferencesPayload> =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(userHotelPreferencesPath, {
          baseURL: config.baseURL,
        });

        resolve(res.data);
      } catch (e) {
        reject(e);
      }
    });

export default fetchUserHotelPreferences;
