import dayjs from "dayjs";
import {
  RecentCarSearch,
  RecentFlightSearch,
  RecentHotelSearch,
  RecentlyViewedHotel,
  RewardsAccount,
} from "redmond";
import { fetchRecentlySearchedHotels } from "../../api/v1/recently-viewed-and-searched/fetchRecentlySearchedHotels";
import { fetchRecentlyViewedHotels } from "../../api/v1/recently-viewed-and-searched/fetchRecentlyViewedHotels";
import {
  RecentlyViewedDesktopP0PropVariantType,
  RECENTLY_VIEWED_DESKTOP_P0_V2,
  RECENTLY_VIEWED_DESKTOP_P0_V4,
  RECENTLY_VIEWED_DESKTOP_P0_V1,
  RECENTLY_VIEWED_DESKTOP_P0_V3,
} from "../../context/experiments";
import { fetchRecentlySearchedFlights } from "../../api/v1/recently-viewed-and-searched/fetchRecentlySearchedFlights";
import { fetchRecentlySearchedCars } from "../../api/v1/recently-viewed-and-searched/fetchRecentlySearchedCars";
export interface RecentlyViewedAndSearchedProps {
  recentSearchesAndViews: (
    | RecentHotelSearch
    | RecentlyViewedHotel
    | RecentFlightSearch
    | RecentCarSearch
  )[];
  largestValueAccount: RewardsAccount;
  latency: number;
  negativeMargin?: boolean;
}

export const fetchRecentlyViewedAndSearched = async (
  largestValueAccount: RewardsAccount,
  variant: RecentlyViewedDesktopP0PropVariantType,
  fetchFlightsRecentlySearched: boolean,
  fetchCarsRecentlySearched: boolean
): Promise<RecentlyViewedAndSearchedProps | false> => {
  const startTime = dayjs();
  let recentSearches: (
    | RecentHotelSearch
    | RecentFlightSearch
    | RecentCarSearch
  )[] = [];
  const recentViews: RecentlyViewedHotel[] = [];

  try {
    const recentlySearchedHotelsResponse = await fetchRecentlySearchedHotels();
    localStorage.setItem(
      "recently_searched_hotels",
      JSON.stringify(recentlySearchedHotelsResponse?.searches || [])
    );
    const event = new Event("update_recently_searched_hotels");
    dispatchEvent(event);
    recentSearches.push(...recentlySearchedHotelsResponse.searches);
  } catch (e) {
    console.error(e);
  }
  try {
    const recentlyViewedResponse = await fetchRecentlyViewedHotels();
    recentViews.push(...recentlyViewedResponse.recentlyViewedLodgings);
  } catch (e) {
    console.error(e);
  }

  if (fetchFlightsRecentlySearched) {
    try {
      const recentlySearchedFlightsResponse =
        await fetchRecentlySearchedFlights();

      localStorage.setItem(
        "recently_searched_flights",
        JSON.stringify(recentlySearchedFlightsResponse?.searches || [])
      );
      const event = new Event("update_recently_searched_flights");
      dispatchEvent(event);
      recentSearches.push(...recentlySearchedFlightsResponse.searches);
    } catch (e) {
      console.error(e);
    }
  }

  if (fetchCarsRecentlySearched) {
    try {
      const recentlySearchedCarsResponse = await fetchRecentlySearchedCars();
      localStorage.setItem(
        "recently_searched_cars",
        JSON.stringify(recentlySearchedCarsResponse?.searches || [])
      );
      const event = new Event("update_recently_searched_cars");
      dispatchEvent(event);
      recentSearches.push(...recentlySearchedCarsResponse?.searches);
    } catch (e) {
      console.error(e);
    }
  }

  if (
    variant === RECENTLY_VIEWED_DESKTOP_P0_V1 ||
    variant === RECENTLY_VIEWED_DESKTOP_P0_V3
  ) {
    recentSearches = recentSearches.sort((a, b) => {
      return (
        new Date(b.searchDate).getTime() - new Date(a.searchDate).getTime()
      );
    });
  }

  let recentSearchesAndViews: (
    | RecentHotelSearch
    | RecentlyViewedHotel
    | RecentFlightSearch
    | RecentCarSearch
  )[] = [...recentSearches, ...recentViews];

  if (
    variant === RECENTLY_VIEWED_DESKTOP_P0_V4 ||
    variant === RECENTLY_VIEWED_DESKTOP_P0_V2
  ) {
    recentSearchesAndViews = recentSearchesAndViews.sort((a, b) => {
      const ADateToCompare =
        (a as RecentFlightSearch).departureDate ??
        (a as RecentCarSearch).pickUpDate ??
        (a as RecentHotelSearch).checkInDate;

      const BDateToCompare =
        (b as RecentFlightSearch).departureDate ??
        (b as RecentCarSearch).pickUpDate ??
        (b as RecentHotelSearch).checkInDate;

      return (
        new Date(ADateToCompare).getTime() - new Date(BDateToCompare).getTime()
      );
    });
  }

  recentSearchesAndViews =
    recentSearchesAndViews.length > 8
      ? recentSearchesAndViews.slice(0, 8)
      : recentSearchesAndViews;

  const latency = dayjs().diff(startTime, "seconds", true);
  if (recentSearchesAndViews.length > 0) {
    return {
      recentSearchesAndViews: recentSearchesAndViews,
      largestValueAccount: largestValueAccount,
      latency,
    };
  } else {
    return false;
  }
};
