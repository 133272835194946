import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { ancillaryPostBookingOfferPath } from "../../paths";
import {
  AncillaryPostBookingFetchOfferRequest,
  AncillaryPostBookingFetchOfferResponse,
} from "redmond";

export const getPostBookingOffer = (
  body: AncillaryPostBookingFetchOfferRequest
): Promise<AncillaryPostBookingFetchOfferResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<AncillaryPostBookingFetchOfferResponse> =
        await axios.post(
          ancillaryPostBookingOfferPath,
          {
            ...body,
            // used for debugging, can manually set this in session storage
            ignoreViewOfferTracking:
              sessionStorage.getItem("ignoreViewOfferTracking") === "true"
                ? true
                : undefined,
          },
          {
            baseURL: config.baseURL,
          }
        );

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getPostBookingOffer;
