import React, { useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router";
import { ExperimentState, ExperimentVariant } from "redmond";
import { isCorpTenant, useUserContext } from "@capone/common";

import itineraryReviewBackground from "../../assets/b2b/itinerary-review-background.jpeg";
import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { colors as caponeColors } from "../../utils/capone/colors";
import { colors as corpColors } from "../../utils/capone-corporate/colors";
import { PAWTUCKET_EXCHANGE_MODULE_ID } from "../../utils/moduleIds";

import "./styles.scss";

export interface IExchangeModuleProps extends RouteComponentProps {
  experiments?: ExperimentState;
  language: string;
}

const defaultProps: Partial<IExchangeModuleProps> = {
  experiments: {} as ExperimentState,
};

const ExchangeModule = (props: IExchangeModuleProps) => {
  const { experiments, language, ...routerProps } = props;
  const { history } = routerProps;
  const { sessionInfo, logo } = useUserContext(config.TENANT);
  const { matchesDesktop, matchesMobile, matchesSmallDesktop } =
    useDeviceTypes();
  const { isLoaded, ptExchangeModule } = useMicrofrontend(
    PAWTUCKET_EXCHANGE_MODULE_ID,
    (config.ptExchangeModuleUrl += "")
  );
  const theme = useTheme();
  let redesignExp = false;

  if (experiments) {
    const { trackingProperties } = experiments;

    redesignExp =
      trackingProperties?.["SelfServeExchangeRedesign"] ===
      ExperimentVariant.AVAILABLE;
  }

  useLayoutEffect(() => {
    if (!ptExchangeModule) return;
    const { render, unMount } = ptExchangeModule;
    const exchangeModuleProps = {
      colors: isCorpTenant(config.TENANT) ? corpColors : caponeColors,
      experiments,
      language,
      theme,
      baseHistory: history,
      clientAssets: {
        itineraryReviewBackground,
        sessionInfo,
        logo: <img src={logo?.src} alt={logo?.alt} />,
      },
      isAgentPortal: config.ptIsAgentPortal,
    };

    if (sessionInfo) {
      render(PAWTUCKET_EXCHANGE_MODULE_ID, exchangeModuleProps);
    }

    return () => unMount(PAWTUCKET_EXCHANGE_MODULE_ID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, sessionInfo]);

  if (!isLoaded) return null;
  if (!ptExchangeModule) return <h2>ERROR! Cannot find Module</h2>;
  return (
    <div
      className={clsx("exchange-module-container", {
        desktop: matchesDesktop || matchesSmallDesktop,
        mobile: matchesMobile,
        redesign: redesignExp,
      })}
      id={PAWTUCKET_EXCHANGE_MODULE_ID}
    />
  );
};

ExchangeModule.defaultProps = defaultProps;

export default ExchangeModule;
