import React, { useLayoutEffect, useMemo } from "react";
import { useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { ExperimentState, CarsModuleProps } from "redmond";
import { isCorpTenant, useUserContext } from "@capone/common";

import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { PAWTUCKET_CARS_MODULE_ID } from "../../utils/moduleIds";
import { colors as caponeColors } from "../../utils/capone/colors";
import { colors as corpColors } from "../../utils/capone-corporate/colors";
import "./styles.scss";

interface ICarsModuleProps extends RouteComponentProps {
  language: string;
  crossSellLoaded: boolean;
  recentlyViewedLoaded: boolean;
  annualTravelCreditLoaded: boolean;
  experiments?: ExperimentState;
}

const CarsModule = (props: ICarsModuleProps) => {
  const {
    language,
    experiments,
    crossSellLoaded,
    recentlyViewedLoaded,
    annualTravelCreditLoaded,
    ...routerProps
  } = props;
  const { history } = routerProps;
  const id = PAWTUCKET_CARS_MODULE_ID;
  const theme = useTheme();
  const { matchesDesktop, matchesSmallDesktop } = useDeviceTypes();
  const { isLoaded, ptCarsModule } = useMicrofrontend(
    id,
    (config.ptCarsModuleUrl += "")
  );
  const { sessionInfo, policies, logo } = useUserContext(config.TENANT);

  const isCrossSell: boolean = useMemo(
    () => crossSellLoaded,
    [crossSellLoaded]
  );

  const isRecentlyViewed: boolean = useMemo(
    () => recentlyViewedLoaded,
    [recentlyViewedLoaded]
  );

  const moduleProps: CarsModuleProps = useMemo(
    () => ({
      colors: isCorpTenant(config.TENANT) ? corpColors : caponeColors,
      experiments,
      language,
      theme,
      baseHistory: history,
      clientAssets: {
        sessionInfo,
        logo: <img src={logo?.src} alt={logo?.alt} />,
        policies,
      },
      isAgentPortal: config.ptIsAgentPortal,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useLayoutEffect(() => {
    if (!ptCarsModule) return;
    const { render, unMount } = ptCarsModule;

    render(id, moduleProps);
    return () => {
      unMount(id);
    };
  }, [isLoaded, moduleProps]); // eslint-disable-line
  if (!isLoaded) return null;
  if (!ptCarsModule) return <h2>ERROR! Cannot find Module</h2>;

  return (
    <div
      className={clsx("cars-module-container", {
        desktop: matchesDesktop || matchesSmallDesktop,
        "cross-sell": isCrossSell,
        "recently-viewed": isRecentlyViewed,
        "annual-travel-credit": annualTravelCreditLoaded,
      })}
      id={id}
    />
  );
};

export default CarsModule;
