import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Footer as GenericFooter, useDeviceTypes } from "halifax";
import { Switch, Route } from "react-router-dom";
import clsx from "clsx";
import { Pathname } from "history";

import HopperLogo from "../../assets/b2b/hopper-logo-bunnyless.svg";
import {
  PATH_FLIGHTS_BOOK_CONFIRMATION,
  POSITION_FIXED_FOOTER_PATHS,
  PATH_TERMS_OF_SERVICE,
  PATH_SITE_TERMS_OF_USE,
  PATHS_WITH_FIXED_RESERVED_COMPONENT,
  ADDITIONAL_MOBILE_REDESIGN_HEADER_FOOTER_PATHS,
} from "../../utils/urlPaths";
import * as constants from "./constants";
import "./styles.scss";
import { ButtonWrap } from "halifax";
import {
  getExperimentVariantCustomVariants,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  useExperiments,
} from "../../context/experiments";
import dayjs from "dayjs";
import config from "../../utils/config";

interface IFooterProps {
  pathname?: Pathname;
}

const Footer = ({ pathname }: IFooterProps) => {
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();
  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  if (
    matchesMobile &&
    pathname &&
    ADDITIONAL_MOBILE_REDESIGN_HEADER_FOOTER_PATHS.includes(pathname) &&
    mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2
  ) {
    return null;
  }

  const renderGenericFooter = () => (
    <B2BGenericFooter
      className={clsx("mclean-generic-footer", config.TENANT, {
        "position-fixed":
          pathname && POSITION_FIXED_FOOTER_PATHS.includes(pathname),
        "has-fixed-mobile-component":
          pathname &&
          matchesMobile &&
          PATHS_WITH_FIXED_RESERVED_COMPONENT.includes(pathname),
      })}
      isMobile={matchesMobile}
    />
  );

  return (
    <Switch>
      <Route
        path={PATH_FLIGHTS_BOOK_CONFIRMATION}
        render={renderGenericFooter}
        exact
      />
      <Route path="*" render={renderGenericFooter} />
    </Switch>
  );
};

export default Footer;

export const B2BGenericFooter = ({
  className,
  isMobile,
}: {
  className?: string;
  isMobile?: boolean;
}) => {
  const renderLinks = () => {
    const renderLink = (texts: string[], urlLink: string) => (
      <ButtonWrap onClick={() => window.open(urlLink, "_blank")}>
        <Box className="button-text-wrapper">
          {/* note: the design is requiring it to line break at a specific word, so that's why it's not a CSS solution */}
          {texts.map((text, index) => (
            <Typography key={index} className="button-text" variant="subtitle2">
              {text}
            </Typography>
          ))}
        </Box>
      </ButtonWrap>
    );

    const links = [
      { texts: [constants.FAQS], urlLink: constants.FAQS_LINK[config.TENANT] },
      {
        texts: isMobile
          ? constants.TERMS_AND_CONDITIONS_MOBILE
          : [constants.TERMS_AND_CONDITIONS_DESKTOP],
        urlLink: PATH_TERMS_OF_SERVICE,
      },
      {
        texts: isMobile
          ? constants.TERMS_OF_USE_MOBILE
          : [constants.TERMS_OF_USE_DESKTOP],
        urlLink: PATH_SITE_TERMS_OF_USE,
      },
      { texts: [constants.PRIVACY], urlLink: constants.PRIVACY_LINK },
    ];

    return (
      <Box className="links-wrapper">
        {links.map((link, index) => {
          return (
            <React.Fragment key={index}>
              {renderLink(link.texts, link.urlLink)}
              {index !== links.length - 1 && (
                <span className="separator">|</span>
              )}
            </React.Fragment>
          );
        })}
      </Box>
    );
  };

  return (
    <GenericFooter
      className={className}
      isMobile={isMobile}
      left={<p>{constants.LEGAL(dayjs().year())}</p>}
      right={
        <ButtonWrap className="hopper-icon-wrapper">
          <span>{constants.POWERED_BY}</span>{" "}
          <img className="hopper-icon" src={HopperLogo} alt="Hopper logo" />
        </ButtonWrap>
      }
      {...(isMobile ? { top: renderLinks() } : { center: renderLinks() })}
    />
  );
};
