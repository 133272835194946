import axios from "axios";
import { config } from "../../config";

import { paymentMethodsApiPrefix } from "../../paths";

import { Payment } from "redmond";

const END_POINT: string = `${paymentMethodsApiPrefix}/list`;

export const listPaymentMethods = (): Promise<IListPaymentMethodsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        END_POINT,
        {},
        {
          baseURL: config.baseURL,
        }
      );

      const responseBody: Payment[] = res.data.value;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IListPaymentMethodsResponse extends Array<Payment> {}
