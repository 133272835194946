import React from "react";
import { Icon, IconName } from "halifax";
import { Typography } from "@material-ui/core";
import config from "../../utils/config";
import clsx from "clsx";
import "./styles.scss";

type MaintenaceProps = {
  title?: string;
  subtext?: string;
};

const Maintenance = (props: MaintenaceProps) => {
  const title = [
    props.title,
    config.MAINTENANCE_TITLE,
    "Service currently unavailable due to scheduled maintenance.",
  ].find(Boolean);

  const subtitle = [
    props.subtext,
    config.MAINTENANCE_SUBTITLE,
    "Please try again soon",
  ].find(Boolean) as string;

  return (
    <div className={clsx("cp-maintenance-content")}>
      {config.CASH_CARD_MAINTENANCE_ICON === "true" ? (
        <Icon className="error-icon" name={IconName.BeachUmbrellaIcon} />
      ) : (
        <Icon className="error-icon" name={IconName.ErrorState} />
      )}
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Typography>{subtitle}</Typography>
    </div>
  );
};

export default Maintenance;
