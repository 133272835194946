export const getRecentlySearchedCardTitle = (
  uiVariant: "hotels" | "cars" | "flights",
  locationNameToUse?: string,
  originLocationNameToUse?: string,
  destinationLocationNameToUse?: string
) => {
  if (uiVariant === "hotels" && locationNameToUse) {
    return `Stays in ${locationNameToUse}`;
  } else if (uiVariant === "cars" && locationNameToUse) {
    return `Rental cars in ${locationNameToUse}`;
  } else if (
    uiVariant === "flights" &&
    originLocationNameToUse &&
    destinationLocationNameToUse
  ) {
    return `${originLocationNameToUse} to ${destinationLocationNameToUse}`;
  } else {
    return undefined;
  }
};

export const getViewAllCTAText = (
  uiVariant: "hotels" | "flights" | "cars",
  location: string
) => {
  switch (uiVariant) {
    case "hotels":
      return `View all hotels in ${location}`;
    case "flights":
      return `View flights to ${location}`;
    case "cars":
      return `View all rental cars in ${location}`;
    default:
      return undefined;
  }
};
