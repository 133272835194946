import axios from "axios";
import { portalAccessPrefix } from "../../paths";

const ENDPOINT = `${portalAccessPrefix}/businessAuthorized`;

const fetchBusinessPortalAuthorized = async (): Promise<boolean> => {
  try {
    const resp = await axios.put(ENDPOINT, {}, {
      withCredentials: true,
    });

    return resp.data.length > 0;
  } catch (e) {
    return false
  }
};

export default fetchBusinessPortalAuthorized;
