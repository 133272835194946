// TODO: remove entire Auth component once readAccess configs are ready on Olympus
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { B2B_PORTAL_JWT_SECRET_KEY } from "redmond";

import { PATH_BASIC_AUTH } from "../../../utils/urlPaths";

interface IBasicAuthProps {
  redirectUrl: string;
}

const BasicAuth = (props: IBasicAuthProps) => {
  const { redirectUrl } = props;
  const query = useQuery();

  return (
    <Route path={PATH_BASIC_AUTH}>
      <AuthRedirect redirectUrl={redirectUrl} jwt={query.get("jwt") ?? ""} />
    </Route>
  );
};

export default BasicAuth;

interface IAuthRedirectProps extends IBasicAuthProps {
  jwt: string;
}

const AuthRedirect = (props: IAuthRedirectProps) => {
  const { redirectUrl, jwt } = props;

  localStorage.setItem(B2B_PORTAL_JWT_SECRET_KEY, jwt);

  return <Redirect to={redirectUrl} />;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
