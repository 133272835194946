import React, { useState } from "react";
import { BasePage } from "./BasePage";
import Warning from "./assets/warning.svg";
import { ActionButton, DesktopPopupModal } from "halifax";
import { Box } from "@material-ui/core";
import { SupportModalContent } from "../../Header/components/SupportModal";

import "./accessDeniedStyles.scss";

export const AccessDenied = () => {
  const [openContactModal, setOpenContactModal] = useState(false);
  const handleSupportModalClose = () => setOpenContactModal(false);

  return (
    <>
      <BasePage
        icon={<img src={Warning} alt="Warning Icon" />}
        title="Access Denied"
        description="Oops, it looks like you don’t have access to this page. To request access please reach out to your internal Company Admin or contact our support team."
        buttons={[
          <ActionButton
            key="go-to-travel"
            defaultStyle="h4r-primary"
            message="Contact Us"
            onClick={() => {
              setOpenContactModal(true);
            }}
          />,
        ]}
      />
      <DesktopPopupModal
        open={openContactModal}
        className="corporate-desktop-contact-support-popup"
        onClose={handleSupportModalClose}
        invisibleBackdrop={false}
      >
        <Box className="corporate-desktop-contact-support-popup-content">
          <SupportModalContent />
        </Box>
      </DesktopPopupModal>
    </>
  );
};
