import axios from "axios";
import { config } from "../../config";
import { userFlightPreferencesPath } from "../../paths";
import { UserFlightPreferencesPayload } from "redmond";

export const fetchUserFlightPreferences =
  (): Promise<UserFlightPreferencesPayload> =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(userFlightPreferencesPath, {
          baseURL: config.baseURL,
        });

        resolve(res.data);
      } catch (e) {
        reject(e);
      }
    });

export default fetchUserFlightPreferences;
