import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((_theme) => ({
  main: {
    width: "100%",
  },
  content: {
    flexGrow: 1,
    position: "relative",
  },
}));

export default useStyles;
