import axios from "axios";
import { ModalName } from "redmond";
import { config } from "../../config";
import { seenModalPath } from "../../paths";

export const updateUserSeenModal = (
  body: IUserSeenModalPayload
): Promise<IUserSeenModalResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(seenModalPath, body, {
        baseURL: config.baseURL,
      });

      const responseBody: IUserSeenModalResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IUserSeenModalPayload {
  modalName: ModalName;
}

export interface IUserSeenModalResponse {}
