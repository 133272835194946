import React, { useEffect, useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core";
import { ExperimentState, TravelWalletModuleProps } from "redmond";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import clsx from "clsx";
import { useUserContext, isCorpTenant } from "@capone/common";

import CapOneLogo from "../../assets/b2b/capone-logo.svg";
import { PAWTUCKET_TRAVEL_WALLET_MODULE_ID } from "../../utils/moduleIds";
import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import "./styles.scss";
import { CAP_ONE_LOGO_ALT } from "../../utils/constants";
import {
  useExperiments,
  getExperimentVariant,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  AVAILABLE,
} from "../../context/experiments";
import { PATH_HOTELS } from "../../utils/urlPaths";
import { colors as caponeColors } from "../../utils/capone/colors";
import { colors as corpColors } from "../../utils/capone-corporate/colors";

interface ITravelWalletModuleProps extends RouteComponentProps {
  language: string;
  experiments?: ExperimentState;
}

const TravelWalletModule = (props: ITravelWalletModuleProps) => {
  const { language, experiments, ...routerProps } = props;
  const { history } = routerProps;
  const id = PAWTUCKET_TRAVEL_WALLET_MODULE_ID;
  const theme = useTheme();
  const { matchesDesktop, matchesSmallDesktop } = useDeviceTypes();
  const { isLoaded, ptTravelWalletModule } = useMicrofrontend(
    id,
    (config.ptTravelWalletModuleUrl += "")
  );
  const { sessionInfo } = useUserContext(config.TENANT);

  const expState = useExperiments();
  const isTravelWalletOfferExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_OFFER_EXPERIMENT
    ) === AVAILABLE;

  useEffect(() => {
    if (experiments?.experiments.length && !isTravelWalletOfferExperiment) {
      history.push(PATH_HOTELS);
    }
  }, [experiments]);

  useLayoutEffect(() => {
    if (!ptTravelWalletModule) return;
    const { render, unMount } = ptTravelWalletModule;
    const travelWalletModuleProps: TravelWalletModuleProps = {
      theme,
      language: language,
      colors: isCorpTenant(config.TENANT) ? corpColors : caponeColors,
      baseHistory: history,
      isAgentPortal: config.ptIsAgentPortal,
      clientAssets: {
        logo: <img src={CapOneLogo} alt={CAP_ONE_LOGO_ALT} />,
        sessionInfo,
      },
      experiments,
    };
    render(id, travelWalletModuleProps);
    return () => {
      unMount(id);
    };
  }, [isLoaded]); // eslint-disable-line

  if (!isLoaded) return null;
  if (!ptTravelWalletModule) return <h2>ERROR! Cannot find Module</h2>;
  return (
    <div
      className={clsx("travel-wallet-module-container", {
        desktop: matchesDesktop || matchesSmallDesktop,
      })}
      id={id}
    />
  );
};

export default TravelWalletModule;
