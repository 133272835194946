import axios from "axios";
import { config } from "../../config";

import { AvailabilityRequest, AvailabilityResponse } from "redmond";
import { hotelAvailabilityApiPrefix } from "../../paths";

export const fetchHotelAvailability = (
  body: AvailabilityRequest
): Promise<AvailabilityResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(hotelAvailabilityApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
