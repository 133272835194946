import axios from "axios";
import { changeBusinessInUsePath } from "../../paths";
import { config } from "../../config";

interface IChangeBusinessInUseRequest {
  businessId: string;
}

export const changeBusinessInUse = (
  body: IChangeBusinessInUseRequest
): Promise<any> => {
  // Stop all pending requests
  window.stop();

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(changeBusinessInUsePath, body, {
        baseURL: config.baseURL,
      });

      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};
