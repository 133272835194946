import axios from "axios";
import { NETWORK_CALL_FAILED, LandingPageTakeovers } from "redmond";

import { config } from "../../config";
import { trackEvent } from "../trackEvent";
import { takeoversApiPrefix } from "../../paths";

const END_POINT: string = `${takeoversApiPrefix}/landingPage`;

export const fetchLandingPageTakeovers = (): Promise<LandingPageTakeovers> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        END_POINT,
        {},
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      trackEvent({
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      });
      reject(e);
    }
  });
