import axios from "axios";

import { config } from "../../config";
import { portalAccessPrefix } from "../../paths";

const END_POINT: string = `${portalAccessPrefix}/consumerAuthorized`;

export const fetchCanAccessConsumer = async (): Promise<boolean> => {
    try {
      const res = await axios.put(
        END_POINT,
        {},
        {
          baseURL: config.baseURL,
        }
      );

      return res.data.length > 0;
    } catch (e) {
      return false;
    }
  };
