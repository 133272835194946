import React from "react";
import { Typography } from "@material-ui/core";
import { ActionLink } from "halifax";

import config from "../../../utils/config";
import * as textConstants from "../constants";
import "./styles.scss";

export const SupportModalContent = () => {
  const supportLinkUrl = textConstants.SUPPORT_LINK_URL[config.TENANT];

  return (
    <>
      <Typography variant="h2" className="support-content-header">
        {textConstants.CONTACT_SUPPORT_HEADER}
      </Typography>
      <Typography variant="h5" className="support-content-text">
        {textConstants.CONTACT_SUPPORT_TEXT_1}
        <ActionLink
          onClick={() => window.open(supportLinkUrl, "_blank")?.focus()}
          content={textConstants.FREQUENTLY_ASKED_QUESTIONS}
          className="support-modal-faq"
        />
      </Typography>
      <Typography variant="h5" className="support-content-text">
        {textConstants.CONTACT_SUPPORT_TEXT_2}
      </Typography>
      <Typography variant="h4" className="support-content-phone">
        {textConstants.CONTACT_SUPPORT_NUMBER}
      </Typography>
    </>
  );
};
