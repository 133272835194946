import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import { store } from "../app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "../serviceWorker";
import config from "../utils/config";
import { datadogRum } from "@datadog/browser-rum";

import "@datadog/browser-logs/bundle/datadog-logs";
import { v4 as uuidv4 } from "uuid";

const sessionId = uuidv4();
window.DD_LOGS.init({
  clientToken: "puba301bc96e3037dcfc2f24329fb3e9b23",
  site: "us5.datadoghq.com",
  service: "corporate-travel-portal",
  version: config.REACT_APP_VERSION,
  env: config.ENV.toLowerCase(),
  sessionSampleRate: 100,
  telemetrySampleRate: 100,
  forwardConsoleLogs: "all",
  trackInteractions: true,
  beforeSend: (log: any) => {
    log.browser_session_id = sessionId;
    log.path = window.location.pathname;
  },
});

datadogRum.init({
  applicationId: "3c765864-be52-47dd-bf69-d0bb1b7906f7",
  clientToken: "pube52726552be1005e36f85062cd5dcf01",
  site: "us5.datadoghq.com",
  service: "corporate-travel-portal",
  env: config.ENV.toLowerCase(),
  version: config.REACT_APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: false,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask",
  allowedTracingUrls: [
    "https://corporate-travel-portal.development.capone.h4r.io/",
    "https://businesstravel-qa.capitalone.com/",
    "http://localhost:3003",
  ],
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
