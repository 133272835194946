import React, { useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core";
import { ExperimentState, PremierCollectionModuleProps } from "redmond";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";
import { RouteComponentProps } from "react-router-dom";
import { useUserContext, isCorpTenant } from "@capone/common";

import ItineraryReviewBackground from "../../assets/b2b/itinerary-review-background.jpeg";
import { PAWTUCKET_PREMIER_COLLECTION_MODULE_ID } from "../../utils/moduleIds";
import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { colors as caponeColors } from "../../utils/capone/colors";
import { colors as corpColors } from "../../utils/capone-corporate/colors";
import "./styles.scss";

interface IPremierCollectionModuleProps extends RouteComponentProps {
  language: string;
  experiments?: ExperimentState;
}

const PremierCollectionModule = (props: IPremierCollectionModuleProps) => {
  const { language, experiments, ...routerProps } = props;
  const { history } = routerProps;
  const id = PAWTUCKET_PREMIER_COLLECTION_MODULE_ID;
  const theme = useTheme();
  const { matchesDesktop, matchesSmallDesktop } = useDeviceTypes();
  const { isLoaded, ptPremierCollectionModule } = useMicrofrontend(
    id,
    (config.ptPremierCollectionModuleUrl += "")
  );
  const { sessionInfo, policies, logo } = useUserContext(config.TENANT);

  useLayoutEffect(() => {
    if (!ptPremierCollectionModule) return;
    const { render, unMount } = ptPremierCollectionModule;
    const premierCollectionModuleProps: PremierCollectionModuleProps = {
      theme,
      language: language,
      colors: isCorpTenant(config.TENANT) ? corpColors : caponeColors,
      baseHistory: history,
      isAgentPortal: config.ptIsAgentPortal,
      experiments,
      clientAssets: {
        logo: <img src={logo?.src} alt={logo?.alt} />,
        itineraryReviewBackground: ItineraryReviewBackground,
        sessionInfo,
        policies,
      },
    };

    render(id, premierCollectionModuleProps);
    return () => {
      unMount(id);
    };
  }, [isLoaded]); // eslint-disable-line

  if (!isLoaded) return null;
  if (!ptPremierCollectionModule) return <h2>ERROR! Cannot find Module</h2>;
  return (
    <div
      className={clsx("premier-collection-module-container", {
        desktop: matchesDesktop || matchesSmallDesktop,
      })}
      id={id}
    />
  );
};

export default PremierCollectionModule;
