import axios from "axios";
import { NETWORK_CALL_FAILED, ActiveExperimentsResponse } from "redmond";
import { trackEvent } from "../trackEvent";
import { config } from "../../config";
import { experimentsApiPrefix } from "../../paths";

const END_POINT: string = `${experimentsApiPrefix}/active`;

export const fetchActiveExperiments = (): Promise<ActiveExperimentsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, {}, { baseURL: config.baseURL });
      resolve(res.data);
    } catch (e) {
      trackEvent({
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      });
      reject(e);
    }
  });
