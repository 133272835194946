import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  RewardsAccount,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
  ModalAlertProperties,
  ModalCategoryType,
  ModalButtonType,
} from "redmond";
import { useHistory } from "react-router-dom";
import { useUserContext } from "@capone/common";
import { GenericInfoPopup, useDeviceTypes } from "halifax";
import config from "../../../utils/config";
import { trackEvent } from "../../../api/v1/trackEvent";
import * as constants from "./constants";
import { HOME_PAGE_PATHS } from "../../../utils/urlPaths";

const VIEWED_CORPORATE_SWITCH_MODAL_KEY = "viewedCorporateSwitchModal";

interface ICorporateSwitchModalProps {
  rewards: RewardsAccount[];
  isFetchingRewards: boolean | undefined;
}

const modalEventProperties: ModalAlertProperties = {
  type: "corporate_intent",
  primary_button: constants.BOOK_BUSINESS_BUTTON_TEXT,
  secondary_button: constants.CONTINUE_BUTTON_TEXT,
  screen: "confirming intent",
  modal_title: constants.SWITCH_MODAL_TITLE,
  modal_subtitle: constants.SWITCH_MODAL_SUBTITLE,
  category: ModalCategoryType.FEATURE,
  agent_title: constants.SWITCH_MODAL_TITLE,
  agent_subtitle: constants.SWITCH_MODAL_SUBTITLE,
  step: "",
  funnel: "other",
};

export const CorporateSwitchModal = ({
  rewards,
  isFetchingRewards,
}: ICorporateSwitchModalProps) => {
  const { isBusinessEligible } = useUserContext("capone");
  const { matchesMobile } = useDeviceTypes();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fromCorporate] = useState<boolean>(
    localStorage.getItem("fromCorporate") === "true"
  );
  const [isPersistentDismiss, setIsPersistentDismiss] = useState(false);

  const history = useHistory();

  const isHomePage = HOME_PAGE_PATHS.includes(history.location.pathname);

  useEffect(() => {
    localStorage.removeItem("fromCorporate");
  }, []);

  const isPersonalCard = (reward: RewardsAccount) =>
    !reward.productDisplayName.startsWith("Spark") ||
    reward.accountUseType === "Personal";

  const isPrimaryRole = (reward: RewardsAccount) =>
    reward.customerAccountRole === "Primary";

  const isMissingAccountDetails = (reward: RewardsAccount) =>
    !reward.accountUseType || !reward.customerAccountRole;

  const canAccessConsumer = useMemo(
    () =>
      // users not in the corporate experiment can access consumer
      rewards.length === 0 ||
      rewards.some(
        (reward) =>
          isPersonalCard(reward) ||
          isPrimaryRole(reward) ||
          // if account details are not available, we can't determine if
          // the account is personal or not so we assume it is
          isMissingAccountDetails(reward)
      ),
    [rewards]
  );

  const hasViewedModal = localStorage.getItem(
    VIEWED_CORPORATE_SWITCH_MODAL_KEY
  );

  const setViewedModal = () => {
    if (isPersistentDismiss) {
      localStorage.setItem(
        VIEWED_CORPORATE_SWITCH_MODAL_KEY,
        Date.now().toString()
      );
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (isFetchingRewards === false) {
      if (!canAccessConsumer && isBusinessEligible) {
        trackEvent({
          eventName: "portal_switched",
          properties: {
            current_portal: "consumer",
            portal_selected: "business",
          },
        });
        window.location.replace(config.corporatePortalUrl!);
      } else if (!hasViewedModal && canAccessConsumer && isBusinessEligible) {
        trackEvent({
          eventName: MODAL_ALERT,
          properties: modalEventProperties,
        });

        setIsOpen(true);
      }
    }
  }, [
    hasViewedModal,
    canAccessConsumer,
    isFetchingRewards,
    isBusinessEligible,
  ]);

  const buttons = [
    {
      buttonText: constants.CONTINUE_BUTTON_TEXT,
      buttonWrapperClassName: "corporate-switch-modal-button",
      defaultStyle: "h4r-secondary" as const,
      onClick: () => {
        trackEvent({
          eventName: MODAL_ALERT_CHOICE,
          properties: {
            ...modalEventProperties,
            button_choice: ModalButtonType.SECONDARY,
          },
        });
        setViewedModal();
      },
    },
    {
      buttonText: constants.BOOK_BUSINESS_BUTTON_TEXT,
      buttonWrapperClassName: "corporate-switch-modal-button",
      defaultStyle: "h4r-primary" as const,
      onClick: () => {
        trackEvent({
          eventName: MODAL_ALERT_CHOICE,
          properties: {
            ...modalEventProperties,
            button_choice: ModalButtonType.PRIMARY,
          },
        });
        trackEvent({
          eventName: "portal_switched",
          properties: {
            current_portal: "consumer",
            portal_selected: "business",
          },
        });
        window.location.assign(config.corporatePortalUrl!);
        setViewedModal();
      },
    },
  ];

  if (fromCorporate || hasViewedModal || !isBusinessEligible || !isHomePage) {
    return null;
  }

  const persistentDismissLabelId = "corporate-persistent-dismiss-label";

  return (
    <GenericInfoPopup
      buttons={buttons}
      image={
        <Typography
          variant="h4"
          style={{ color: "var(--secondary-text-grey)" }}
        >
          Welcome to Capital One Travel
        </Typography>
      }
      isAgent={false}
      isMobile={matchesMobile}
      open={isOpen}
      subtitle={constants.SWITCH_MODAL_SUBTITLE}
      title={constants.SWITCH_MODAL_TITLE}
      bottomContent={
        <Box mt={1}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                inputProps={{ "aria-labelledby": persistentDismissLabelId }}
                checked={isPersistentDismiss}
                onChange={(e) => setIsPersistentDismiss(e.target.checked)}
              />
            }
            label={
              <Typography id={persistentDismissLabelId}>
                Do not ask me again
              </Typography>
            }
          />
        </Box>
      }
    />
  );
};

export default CorporateSwitchModal;
