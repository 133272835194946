import dayjs from "dayjs";
import {
  CarEntryTypeEnum,
  FlightEntryTypeEnum,
  FlightShopStep,
  HotelEntryTypeEnum,
  LodgingSelection,
  PackagesEntryTypeEnum,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
export const transformToStringifiedAvailabilityQuery = (
  location: string,
  fromDate: Date | string | null,
  untilDate: Date | string | null,
  adultsCount: number | null,
  children: number[] | null,
  roomsCount?: number,
  entryPoint?: HotelEntryTypeEnum
): string => {
  location = encodeURIComponent(location);
  if (fromDate === null || untilDate === null) {
    return `?locationName=${location}`;
  }

  const formatFrom =
    typeof fromDate !== "string"
      ? dayjs(fromDate).format("YYYY-MM-DD")
      : fromDate;
  const formatUntil =
    typeof untilDate !== "string"
      ? dayjs(untilDate).format("YYYY-MM-DD")
      : untilDate;

  const childrenParams = children?.reduce(
    (ageString, age) => `${ageString}&children=${age}`,
    ""
  );

  let query = `?locationName=${location}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${childrenParams}`;

  if (roomsCount) {
    query += `&roomsCount=${roomsCount}`;
  }
  if (entryPoint) {
    query += `&entryPoint=${entryPoint}`;
  }
  return query;
};

export interface HotelShopQuery {
  lodgingId: string;
  fromDate: Date | string | null;
  untilDate: Date | string | null;
  adultsCount: number;
  children: number[];
  selectedAccountIndex: number;
  selectedLodgingIndex: number | null;
  lodgingSelection?: LodgingSelection;
  roomsCount?: number;
  petsCount?: number;
  fromHotelAvailability?: boolean;
  entryPoint?: HotelEntryTypeEnum;
}

export const transformToStringifiedQuery = ({
  lodgingId,
  fromDate,
  untilDate,
  adultsCount = 2,
  children = [],
  selectedAccountIndex = 0,
  selectedLodgingIndex,
  lodgingSelection,
  roomsCount,
  petsCount,
  fromHotelAvailability,
  entryPoint,
}: HotelShopQuery): string => {
  if (fromDate === null || untilDate === null) {
    return `?lodgingId=${lodgingId}`;
  }

  const formatFrom =
    typeof fromDate !== "string"
      ? dayjs(fromDate).format("YYYY-MM-DD")
      : fromDate;
  const formatUntil =
    typeof untilDate !== "string"
      ? dayjs(untilDate).format("YYYY-MM-DD")
      : untilDate;

  const childrenParams = children?.reduce(
    (ageString, age) => `${ageString}&children=${age}`,
    ""
  );

  const lodgingSelectionParam = encodeURIComponent(
    JSON.stringify(lodgingSelection)
  );
  let string = "";
  if (roomsCount) {
    string = `?lodgingId=${lodgingId}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${childrenParams}&roomsCount=${roomsCount}&selectedAccountIndex=${selectedAccountIndex}&lodgingSelection=${lodgingSelectionParam}`;
  } else {
    string = `?lodgingId=${lodgingId}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${childrenParams}&selectedAccountIndex=${selectedAccountIndex}&lodgingSelection=${lodgingSelectionParam}`;
  }

  if (petsCount) {
    string += `&pets=${petsCount != 0}`;
  }

  if (selectedLodgingIndex != null) {
    string += `&selectedLodgingIndex=${selectedLodgingIndex}`;
  }

  if (fromHotelAvailability !== undefined) {
    string += `&fromHotelAvailability=${fromHotelAvailability}`;
  }
  if (entryPoint) {
    string += `&entryPoint=${entryPoint}`;
  }
  return string;
};

export interface FlightShopQuery {
  tripCategory: TripCategory;
  origin: string;
  destination: string;
  departureDate: string;
  returnDate?: string;
  stopsOptions?: SliceStopCountFilter;
  noLCC?: boolean;
  flightShopProgress?: FlightShopStep;
  adultsCounts: number;
  childrenCount?: number;
  infantsInSeatsCount?: number;
  infantsOnLapCount?: number;
  entryPoint?: FlightEntryTypeEnum;
  fareClass?: string[];
}

export const transformToStringifiedFlightShopQuery = ({
  tripCategory,
  origin,
  destination,
  departureDate,
  returnDate,
  stopsOptions = SliceStopCountFilter.ANY_NUMBER,
  noLCC = false,
  flightShopProgress = FlightShopStep.ChooseDeparture,
  adultsCounts,
  childrenCount,
  infantsInSeatsCount,
  infantsOnLapCount,
  entryPoint,
  fareClass,
}: FlightShopQuery) => {
  const formatDepartureDate =
    typeof departureDate !== "string"
      ? dayjs(departureDate).format("YYYY-MM-DD")
      : departureDate;
  const formatReturnDate =
    returnDate && typeof returnDate !== "string"
      ? dayjs(returnDate).format("YYYY-MM-DD")
      : returnDate;

  let string = "";
  if (adultsCounts) {
    string += `?adultsCount=${adultsCounts}`;
  }
  if (childrenCount) {
    string += `&childrenCount=${childrenCount}`;
  }
  if (infantsInSeatsCount) {
    string += `&infantsInSeatCount=${infantsInSeatsCount}`;
  }
  if (infantsOnLapCount) {
    string += `&infantsOnLapCount=${infantsOnLapCount}`;
  }
  fareClass?.forEach((fc) => (string += `&fareClass=${fc}`));
  if (formatDepartureDate && origin && destination) {
    string += `&departureDate=${formatDepartureDate}&destination=${destination}&flightShopProgress=${flightShopProgress}&noLCC=${noLCC}&origin=${origin}`;
  }
  if (formatReturnDate) {
    string += `&returnDate=${formatReturnDate}&stopsOption=${stopsOptions}&tripCategory=${tripCategory}&entryPoint=${entryPoint}`;
  } else {
    string += `&stopsOption=${stopsOptions}&tripCategory=${tripCategory}&entryPoint=${entryPoint}`;
  }
  return string;
};

export interface CarAvailabilityQuery {
  dropOffDate: string;
  dropOffTime: string;
  dropOffLocation: string;
  pickUpDate: string;
  pickUpTime: string;
  pickUpLocation: string;
  driverAge: number;
  entryPoint?: CarEntryTypeEnum;
}
export const transformToStringifiedCarsAvailabilityQuery = ({
  dropOffDate,
  dropOffTime,
  dropOffLocation,
  pickUpDate,
  pickUpTime,
  pickUpLocation,
  driverAge,
  entryPoint,
}: CarAvailabilityQuery) => {
  const formatDropOffDate = dayjs(dropOffDate).format("YYYY-MM-DD");
  const formatPickUpDate = dayjs(pickUpDate).format("YYYY-MM-DD");

  let string =
    `?dropOffDate=${formatDropOffDate}` +
    `&dropOffTime=${dropOffTime}` +
    `&dropOffLocation=${dropOffLocation}` +
    `&pickUpDate=${formatPickUpDate}` +
    `&pickUpTime=${pickUpTime}` +
    `&pickUpLocation=${pickUpLocation}` +
    `&driverAge=${driverAge}`;
  if (entryPoint) {
    string += `&entryPoint=${entryPoint}`;
  }
  return string;
};

export interface PackagesAvailabilityQuery {
  origin?: string | null;
  destination?: string | null;
  fromDate?: Date | string | null;
  untilDate?: Date | string | null;
  fareClass?: string;
  stopsOption: SliceStopCountFilter;
  adultsCount: number;
  entryPoint?: PackagesEntryTypeEnum;
}

export const transformToStringifiedPackagesAvailabilityQuery = ({
  origin,
  destination,
  fromDate,
  untilDate,
  fareClass,
  stopsOption,
  adultsCount,
  entryPoint,
}: PackagesAvailabilityQuery) => {
  const queryParams = new URLSearchParams({
    origin: origin ?? "",
    destination: destination ?? "",
    stopsOption,
    adultsCount: adultsCount.toString(),
  });

  if (fromDate) {
    const formattedFromDate = dayjs(fromDate).format("YYYY-MM-DD");
    queryParams.append("fromDate", formattedFromDate);
  }

  if (untilDate) {
    const formattedUntilDate = dayjs(untilDate).format("YYYY-MM-DD");
    queryParams.append("untilDate", formattedUntilDate);
  }

  if (fareClass) {
    queryParams.append("fareClass", fareClass);
  }

  if (entryPoint) {
    queryParams.append("entryPoint", entryPoint);
  }

  return `?${queryParams.toString()}`;
};
