import React from "react";
import "./styles.scss";
import { RewardsAccount } from "redmond";

import dispatchEvent from "../../utils/events";
import {
  RewardsAccountSelection as AccountSelection,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import { CorpBusinessAccount } from "redmond/apis/tysons/businesses";
import { trackEvent } from "../../api/v1/trackEvent";
export interface IRewardsAccountSelectionProps {
  rewardsAccounts: RewardsAccount[];
  selectedRewardsAccountId: string | null;
  setSelectedRewardsAccountId: (id: string | null) => void;
  locationPath?: string;
  corpBusinessAccounts?: CorpBusinessAccount[];
  activeAccountBusinessId?: string;
  handleChangeBusinessInUse?: (selectedBusinessId: string) => void;
  isAccountSwitchLoading?: boolean;
}

export const RewardsAccountSelection = (
  props: IRewardsAccountSelectionProps
) => {
  const {
    rewardsAccounts,
    selectedRewardsAccountId,
    setSelectedRewardsAccountId,
    corpBusinessAccounts,
    activeAccountBusinessId,
    handleChangeBusinessInUse,
    isAccountSwitchLoading,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const onSelectedRewardsAccount = (accountId: string | null) => {
    localStorage.setItem("referenceId", accountId ?? "");
    dispatchEvent("mclean_rewards_account_selected", accountId);
  };

  React.useEffect(() => {
    const accountRefId = localStorage.getItem("referenceId");
    setSelectedRewardsAccountId(accountRefId);
  }, [setSelectedRewardsAccountId]);

  return (
    <AccountSelection
      selectedAccountReferenceId={selectedRewardsAccountId}
      setSelectedAccountReferenceId={(accountId) => {
        onSelectedRewardsAccount(accountId);
        setSelectedRewardsAccountId(accountId);
      }}
      rewardsAccounts={rewardsAccounts}
      className={clsx("b2b", {
        "mobile-landing-rewards-selection": matchesMobile,
      })}
      popoverClassName="b2b"
      isMobile={matchesMobile}
      corpBusinessAccounts={corpBusinessAccounts}
      activeAccountBusinessId={activeAccountBusinessId}
      handleChangeBusinessInUse={handleChangeBusinessInUse}
      isAccountSwitchLoading={isAccountSwitchLoading}
      trackEvent={trackEvent}
    />
  );
};
