// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/b2b/home-screen-redesign-background.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".packages-module-container .App .packages-search-root .packages-search-container-desktop{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat;background-position:center top;background-blend-mode:multiply;width:100%;display:flex;flex-direction:column;align-items:center}.packages-module-container .App .packages-search-root .packages-search-container-desktop .packages-search-titles-and-fields-wrapper{padding:180px 135px 100px 135px;width:1000px}.packages-module-container .App .packages-flight-shop-root{padding:0;background-color:var(--white)}", "",{"version":3,"sources":["webpack://./../b2b-base/src/components/PackagesModule/styles.scss"],"names":[],"mappings":"AAGM,yFACE,wDAAA,CACA,qBAAA,CACA,2BAAA,CACA,8BAAA,CACA,8BAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,oIACE,+BAAA,CACA,YAAA,CAKN,2DACE,SAAA,CACA,6BAAA","sourcesContent":[".packages-module-container {\n  .App {\n    .packages-search-root {\n      .packages-search-container-desktop {\n        background-image: url(\"../../assets/b2b/home-screen-redesign-background.jpg\");\n        background-size: cover;\n        background-repeat: no-repeat;\n        background-position: center top;\n        background-blend-mode: multiply;\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        .packages-search-titles-and-fields-wrapper {\n          padding: 180px 135px 100px 135px;\n          width: 1000px;\n        }\n      }\n    }\n\n    .packages-flight-shop-root {\n      padding: 0;\n      background-color: var(--white);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
