import { Box, Typography } from "@material-ui/core";
import React from "react";

import "./basePageStyles.scss";

export interface IBasePageProps {
  icon: React.ReactNode;
  title: string;
  description: string | React.ReactNode;
  buttons?: React.ReactNode[];
}

export const BasePage = ({
  icon,
  title,
  description,
  buttons,
}: IBasePageProps) => {
  return (
    <Box className="limited-access-page">
      <Box className="limited-access-page-content">
        <Box className="limited-access-page-icon">{icon}</Box>
        <Typography variant="h1" className="limited-access-page-title">
          {title}
        </Typography>
        <Typography variant="body2" align="center">
          {description}
        </Typography>
        <Box className="limited-access-page-buttons">{buttons}</Box>
      </Box>
    </Box>
  );
};
