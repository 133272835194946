import { config } from "../config";
import { fetchUserInfoPath } from "../paths";
import { mcLeanAxiosInstance } from "../../components/AxiosInterceptors";
import { CorpSessionInfo, SessionInfo, Tenant } from "redmond";

export const fetchUserInfo = <T extends Tenant>(): Promise<
  T extends "capone-corporate" ? CorpSessionInfo : SessionInfo
> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await mcLeanAxiosInstance.get(fetchUserInfoPath, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchUserInfo;
