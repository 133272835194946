import {
  ActionButton,
  AnnualTravelCreditInfoBanner,
  AnnualTravelCreditInfoModal,
  getTravelCreditHistoryModalProps,
  TravelCreditHistoryModal,
} from "halifax";
import React from "react";
import clsx from "clsx";
import {
  RewardsAccount,
  Transaction,
  VIEWED_CREDIT_BENEFITS_MODAL,
  WalletDetailsResponse,
} from "redmond";
import { fetchTravelWalletCreditHistory } from "../../api/v1/takeovers/fetchTravelWalletCreditHistory";
import "./styles.scss";
import dayjs from "dayjs";
import { trackEvent } from "../../api/v1/trackEvent";

interface HomepageAnnualTravelCreditProps {
  firstName: string;
  rewardsAccounts: RewardsAccount[];
  walletDetails: WalletDetailsResponse;
  showBanner?: boolean;
  isMobile?: boolean;
  isAnnualTravelCreditsExperiment?: boolean;
  negativeMargin?: boolean;
}

export const HomepageAnnualTravelCredit = ({
  firstName,
  rewardsAccounts,
  walletDetails,
  showBanner = true,
  isMobile = false,
  isAnnualTravelCreditsExperiment,
  negativeMargin = true,
}: HomepageAnnualTravelCreditProps) => {
  const [openCreditHistoryModal, setOpenCreditHistoryModal] =
    React.useState<boolean>(false);

  const [creditsTransactionHistory, setCreditsTransactionHistory] =
    React.useState<Transaction[]>([]);
  const [ftuxModalOpen, setFtuxModalOpen] = React.useState<boolean>(false);
  const [ftuxProducts, setFtuxProducts] = React.useState<{
    ventureX: boolean;
    ventureXBusiness: boolean;
  }>({ ventureX: false, ventureXBusiness: false });

  const modalProps = getTravelCreditHistoryModalProps(
    firstName,
    creditsTransactionHistory,
    walletDetails.credit
  );

  React.useEffect(() => {
    fetchTravelWalletCreditHistory().then((response) => {
      setCreditsTransactionHistory(response.credits);
    });
  }, []);

  React.useEffect(() => {
    const lsItem = localStorage.getItem("atc_ftux_modal_shown");
    const modalShown = lsItem === "true";

    if (!modalShown) {
      const hasPreviouslyCreatedStatementCredit =
        !!walletDetails.creditBreakdown.find(
          (creditDetail) =>
            creditDetail.CreditDetail === "Statement" &&
            dayjs().isAfter(creditDetail.createdOn, "day")
        );

      // don't show FTUX if user already had a credit before
      if (!hasPreviouslyCreatedStatementCredit) {
        const vxStatementCreditCreatedToday =
          walletDetails.creditBreakdown.some(
            (creditDetail) =>
              creditDetail.CreditDetail === "Statement" &&
              creditDetail.productType === "VentureX" &&
              dayjs().isSame(creditDetail.createdOn, "day")
          );

        const vxbStatementCreditCreatedToday =
          walletDetails.creditBreakdown.some(
            (creditDetail) =>
              creditDetail.CreditDetail === "Statement" &&
              creditDetail.productType === "VentureXBusiness" &&
              dayjs().isSame(creditDetail.createdOn, "day")
          );

        if (vxStatementCreditCreatedToday || vxbStatementCreditCreatedToday) {
          setFtuxProducts({
            ventureX: vxStatementCreditCreatedToday,
            ventureXBusiness: vxbStatementCreditCreatedToday,
          });
          setFtuxModalOpen(true);
          trackEvent({
            eventName: VIEWED_CREDIT_BENEFITS_MODAL,
            properties: {},
          });
          localStorage.setItem("atc_ftux_modal_shown", "true");
        }
      }
    }
  }, [walletDetails.creditBreakdown, rewardsAccounts]);

  return (
    <div
      className={clsx("home-page-atc-info-banner-container", {
        mobile: isMobile,
        "show-banner": showBanner,
        "negative-margin": negativeMargin,
      })}
    >
      {showBanner && (
        <>
          <AnnualTravelCreditInfoBanner
            variant={isMobile ? "small" : "large"}
            firstName={firstName}
            rewardsAccounts={rewardsAccounts}
            creditBreakdown={walletDetails.creditBreakdown}
            onClickCTA={() => setOpenCreditHistoryModal(true)}
          />
          <AnnualTravelCreditInfoBanner
            variant={isMobile ? "small" : "large"}
            firstName={firstName}
            rewardsAccounts={rewardsAccounts}
            creditBreakdown={walletDetails.creditBreakdown}
            onClickCTA={() => setOpenCreditHistoryModal(true)}
            cardType="Venture"
          />
          {isMobile && (
            <ActionButton
              className="b2b"
              message="View travel credits"
              onClick={() => setOpenCreditHistoryModal(true)}
            />
          )}
          <TravelCreditHistoryModal
            modalContent={modalProps.modalContentProps}
            openCreditHistoryModal={openCreditHistoryModal}
            setOpenCreditHistoryModal={setOpenCreditHistoryModal}
            creditTransactionsHistory={modalProps.creditTransactionsHistory}
            isMobile={isMobile}
            rewardsAccounts={rewardsAccounts}
            credit={walletDetails.credit}
            creditBreakdown={walletDetails.creditBreakdown}
            isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
          />
        </>
      )}
      <AnnualTravelCreditInfoModal
        hasVentureX={ftuxProducts.ventureX}
        hasVentureXBusiness={ftuxProducts.ventureXBusiness}
        open={ftuxModalOpen}
        setOpen={setFtuxModalOpen}
        isMobile={isMobile}
      />
    </div>
  );
};
