import dayjs from "dayjs";
import { IResult, RewardsAccount } from "redmond";

export const getEarnTagText = (
  rewardsAccount: RewardsAccount,
  currency: string,
  uiVariant: "hotels" | "cars" | "flights"
) => {
  const earnRateToUse = `${uiVariant}Multiplier`;
  const earnRate = rewardsAccount.earn[earnRateToUse];
  const multiplier = currency.includes("cash") ? "%" : "X";
  if (earnRate) {
    return `Earn <span class="font-bold">${earnRate}${multiplier} ${currency}</span> on ${uiVariant}`;
  } else {
    return undefined;
  }
};

export const getCrossSellEarnText = (rewardsAcct?: RewardsAccount) => {
  if (!rewardsAcct) return "";
  const currency =
    rewardsAcct.rewardsBalance.currency ??
    rewardsAcct.rewardsBalance.currencyDescription;
  const multiplier = currency.toLowerCase().includes("cash") ? "%" : "X";

  return `Earn ${
    rewardsAcct.earn.hotelsMultiplier
  }${multiplier} ${currency.toLowerCase()} with your ${
    rewardsAcct.productDisplayName
  } account.`;
};

export const getBannerText = (
  location: IResult | null,
  fromDate: Date,
  untilDate: Date,
  rewardsAcct: RewardsAccount,
  isMobile?: boolean,
  shortEarnText?: boolean
) => {
  if (!rewardsAcct) {
    return "";
  }
  const currency =
    rewardsAcct.rewardsBalance.currency ??
    rewardsAcct.rewardsBalance.currencyDescription;
  const multiplier = currency.toLowerCase().includes("cash") ? "%" : "X";

  const [city, state] = location?.label ? location.label.split(",") : [];

  if (isMobile) {
    return `Choose from a variety of hotels in <br> <strong>${city}, ${state}</strong> from <strong>${dayjs(
      fromDate
    ).format("MMM, D")} - ${dayjs(untilDate).format("MMM, D")}</strong>`;
  } else if (shortEarnText) {
    return `Choose from a variety of hotels in <strong>${city}, ${state}</strong> from <strong>${dayjs(
      fromDate
    ).format("MMM, D")} - ${dayjs(untilDate).format(
      "MMM, D"
    )}</strong> <span class="hotel-cross-sell-banner-rewards-text">
      Earn ${
        rewardsAcct.earn.hotelsMultiplier
      }${multiplier} ${currency.toLowerCase()} with your ${
      rewardsAcct.productDisplayName
    }
      account.</span>`;
  } else {
    return `Choose from a variety of hotels in <strong>${city}, ${state}</strong> from <strong>${dayjs(
      fromDate
    ).format("MMM, D")} - ${dayjs(untilDate).format(
      "MMM, D"
    )}</strong><span class="hotel-cross-sell-banner-rewards-text">Book with flexibility and earn ${
      rewardsAcct.earn.hotelsMultiplier
    }${multiplier} ${currency.toLowerCase()} with your ${
      rewardsAcct.productDisplayName
    } account.</span>`;
  }
};

export const getEarnBannerText = (
  earnRate: string | number,
  currency: string,
  productName: string
) => {
  const multiplier = currency.toLowerCase().includes("cash") ? "%" : "X";
  return `Earn <span class="font-bold">${earnRate}${multiplier} ${currency}</span> with your ${productName} account`;
};

export const getViewButtonText = (
  locationName: string,
  isInBanner?: boolean
) => {
  if (isInBanner) {
    return `View all hotels in ${locationName}`;
  }
  return `View more hotels in ${locationName}`;
};
