import React from "react";
import {
  CREDIT_HOW_DESCRIPTION,
  CREDIT_HOW_TITLE,
  CREDIT_STACKING_ADDITIONAL_DESCRIPTION,
  CurrencyFormatters,
} from "halifax";
import { CreditDetail, TravelWalletOffer, WallStreetAmount } from "redmond";
import { Box, Typography } from "@material-ui/core";
import { config } from "../../api/config";

export const SINGLE_EXPIRING_PF_SUBTITLE = (cityName: string) =>
  `Reminder! The price you froze for your trip to ${cityName} is set to expire.`;
export const SINGLE_HOTEL_EXPIRING_PF_SUBTITLE = (hotelName: string) =>
  `Reminder! The price you froze for ${hotelName} is set to expire. `;
export const MULTIPLE_EXPIRING_PFS_SUBTITLE = `Reminder! You have multiple frozen trips that are set to expire. Go to`;
export const COMPLETE_BOOKING_NOW = "Complete booking now";
export const MY_TRIPS = "My Trips";
export const MULTIPLE_OFFER_SUBTITLE = (offerCount: number) =>
  `You have <strong>(${offerCount})</strong> travel offers available!`;
export const BEST_OFFER_SUBTITLE = (offer: TravelWalletOffer) => {
  return offer.landingPageBanner ? offer.landingPageBanner : undefined;
};

export const VIEW_OFFER_DETAILS = "View Offer Details";
export const VIEW_TRAVEL_OFFERS = "View travel offers";

export const VIEW_DETAILS = "View Details";
export const CREDITS_SUBTITLE = (amount?: WallStreetAmount) =>
  `You have <strong>${CurrencyFormatters.get(
    amount?.currency,
    undefined,
    "en-US"
  ).format(
    Math.abs(amount?.amount || 0)
  )}</strong> in travel credits available. Credits applied at checkout.`;
export const CREDITS_MODAL_HEADER_TEXT = (name?: string) =>
  `${name ? `${name}'s ` : ""}<strong>travel credit</strong> summary`;
export const CREDITS_MODAL_DETAILS_CARD_PROPS = (
  creditBreakdown: CreditDetail[],
  name?: string,
  amount?: WallStreetAmount,
  isStackingApplicable?: boolean
) => ({
  balanceCardProps: {
    creditBreakdown,
    description: `${name ? `${name}'s ` : ""}Travel Credits`,
    balanceText: `${CurrencyFormatters.get(
      amount?.currency,
      undefined,
      "en-US"
    ).format(Math.abs(amount?.amount || 0))} available to spend on travel`,
    applicabilityText: "Valid on all bookings",
  },
  accordionContents: [
    {
      title: CREDIT_HOW_TITLE,
      body: (
        <Box className="credits-how">
          <Typography className="credits-how-description">
            {isStackingApplicable ? (
              <>
                <li>{CREDIT_HOW_DESCRIPTION(config.TENANT)}</li>
                <br />
                <li>{CREDIT_STACKING_ADDITIONAL_DESCRIPTION(config.TENANT)}</li>
              </>
            ) : (
              CREDIT_HOW_DESCRIPTION(config.TENANT)
            )}
          </Typography>
        </Box>
      ),
    },
  ],
  terms: (
    <>
      <strong>Terms and Conditions:</strong> Lorem ipsum dolor sit amet,
      consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
      ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
      dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
      nulla pariatur.
    </>
  ),
});

export const CREDIT_BALANCE_DESCRIPTION = (name?: string) =>
  `${name}'s travel credits`;

export const VIEW_CREDIT_HISTORY_DESCRIPTION =
  "View your travel credit activity";
export const VIEW_CREDIT_HISTORY_CTA = "View activity";
export const CREDIT_HISTORY_MODAL_SUBHEADER = "Available to spend on travel";
export const CREDIT_HISTORY_MODAL_LIST_HEADER = "Travel credit activity:";

export const CREDIT_HISTORY_BALANCE_TEXT = (
  currencySymbol: string,
  amount: string
) => {
  const [dollars, cents] = amount.split(".");
  return (
    <>
      <span className="balance-amount-currency-symbol">{currencySymbol}</span>
      {/* since the currency symbol is in it's own span for styling purposes, it is removed from the dollars string */}
      {dollars.replace(currencySymbol, "")}
      <span className="balance-amount-cents">{cents}</span>
    </>
  );
};

export const DISCOUNT_INFO_TEXT = (promoText: string) =>
  `Plan your trip worry-free. After selecting your flight, add the flexibility to cancel it for any reason for ${promoText} the usual fee (available for 1 booking per account on eligible flights departing before August 7, 2023).`;
