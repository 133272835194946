import axios from "axios";
import { NETWORK_CALL_FAILED, RewardsAccountsResponse } from "redmond";

import { config } from "../../config";
import { trackEvent } from "../trackEvent";
import { rewardsApiPrefix } from "../../paths";

const END_POINT: string = `${rewardsApiPrefix}/accounts`;

export const fetchRewardsAccounts = (): Promise<RewardsAccountsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        END_POINT,
        {},
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      trackEvent({
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      });
      reject(e);
    }
  });
