import React, { useEffect, useState } from "react";
import {
  IIdLodgings,
  IResponse,
  LocationQueryEnum,
  LodgingSelection,
  LodgingSelectionEnum,
  ProductFeedViewedProperties,
  PRODUCT_FEED_VIEWED_EVENT,
  RecentHotelSearch,
  RecentlyViewedHotel,
  RecentFlightSearch,
  ProductTileType,
  RecentCarSearch,
} from "redmond";
import { Box } from "@material-ui/core";
import { RecentlySearchedCard } from "./components/RecentlySearchedCard";
import {
  BackButton,
  useDeviceTypes,
  PremierCollectionBenefitsModal,
} from "halifax";
import "./styles.scss";
import { useInView } from "react-intersection-observer";
import { RecentlyViewedAndSearchedProps } from "../Body/recently-viewed-and-searched-helpers";
import { RecentlyViewedCard } from "./components/RecentlyViewedCard";
import {
  useExperiments,
  getExperimentVariantCustomVariants,
  RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
  RECENTLY_VIEWED_DESKTOP_P0_VARIANTS,
  AVAILABLE,
  getExperimentVariant,
  PREMIER_COLLECTION_EXPERIMENT,
  RECENTLY_VIEWED_DESKTOP_P0_V1,
  RECENTLY_VIEWED_DESKTOP_P0_V2,
  RECENTLY_VIEWED_MOBILE_P0_EXPERIMENT,
  RECENTLY_VIEWED_MOBILE_P0_V1,
  RECENTLY_VIEWED_MOBILE_P0_VARIANTS,
  RECENTLY_VIEWED_MOBILE_P0_V2,
  RECENTLY_VIEWED_MOBILE_P0_V3,
} from "../../context/experiments";
import clsx from "clsx";
import { fetchLocationAutocomplete } from "../../api/v1/recently-viewed-and-searched/fetchLocationAutocomplete";
import { trackEvent } from "../../api/v1/trackEvent";

export const RecentlyViewedAndSearched = (
  props: RecentlyViewedAndSearchedProps
) => {
  const {
    recentSearchesAndViews,
    largestValueAccount,
    latency,
    negativeMargin = true,
  } = props;
  const [isOverFlow, setIsOverFlow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [lodgingSelectionsMap, setLodgingSelectionsMap] = useState<{
    [key: string]: LodgingSelection;
  }>({});
  const [hasRecentlyViewedHotel, setHasRecentlyViewedHotel] = useState(false);
  const [hasRecentlySearchedHotel, setHasRecentlySearchedHotel] =
    useState(false);
  const [hasRecentlySearchedFlight, setHasRecentlySearchedFlight] =
    useState(false);
  const [hasRecentlySearchedCar, setHasRecentlySearchedCar] = useState(false);
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();

  const recentlyViewedDesktopP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
    RECENTLY_VIEWED_DESKTOP_P0_VARIANTS
  );

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const recentlyViewedMobileP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    RECENTLY_VIEWED_MOBILE_P0_EXPERIMENT,
    RECENTLY_VIEWED_MOBILE_P0_VARIANTS
  );

  const { ref } = useInView({
    root: document.getElementById("recently-searched-card-view-wrapper"),
    threshold: 0.5,
    rootMargin: "0px 0px 0px 350px",
  });

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };
  const determineOverFlow = () => {
    if (!matchesMobile) {
      const overFlowedElement = document.getElementById(
        "recently-viewed-and-searched-cards-wrapper"
      );

      if (overFlowedElement) {
        return overFlowedElement.clientWidth < overFlowedElement.scrollWidth;
      }
    }
    return false;
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    const overFlow = determineOverFlow();
    setIsOverFlow(overFlow);
  }, [windowWidth]);

  useEffect(() => {
    if (recentSearchesAndViews?.length) {
      if (!matchesMobile) {
        (async () => {
          let autocompleteMap: {
            [key: string]: LodgingSelection;
          } = {};

          for (let i = 0; i <= recentSearchesAndViews.length; i++) {
            const recentItem = recentSearchesAndViews[i] as RecentHotelSearch;
            if (
              recentItem &&
              !(recentItem?.location in autocompleteMap) &&
              ("lodging" in recentItem ||
                recentlyViewedDesktopP0Variant ===
                  RECENTLY_VIEWED_DESKTOP_P0_V1 ||
                recentlyViewedDesktopP0Variant ===
                  RECENTLY_VIEWED_DESKTOP_P0_V2)
            ) {
              const fetchLocation = async () => {
                try {
                  const { categories: locationCategories }: IResponse =
                    await fetchLocationAutocomplete({
                      l: recentItem.location,
                      LocationQuery: LocationQueryEnum.Label,
                    });
                  const correspondingLocations = locationCategories.flatMap(
                    (category) =>
                      category.results.find((result) =>
                        result.label
                          .toLowerCase()
                          .includes(recentItem.location.toLowerCase())
                      )
                  );
                  const allLocations = locationCategories.flatMap(
                    (category) => category.results
                  );
                  const updatedLocation =
                    correspondingLocations.length > 0
                      ? correspondingLocations[0]
                      : allLocations[0];
                  autocompleteMap[recentItem.location] = (
                    updatedLocation?.id as IIdLodgings
                  ).lodgingSelection;
                } catch {
                  autocompleteMap[recentItem.location] = {
                    placeId: "",
                    searchTerm: recentItem.location,
                    placeTypes: ["locality", "political", "geocode"],
                    LodgingSelection: LodgingSelectionEnum.Place,
                  };
                }
              };
              await fetchLocation();
            }
            setLodgingSelectionsMap((prevState) => {
              return {
                ...prevState,
                ...autocompleteMap,
              };
            });
          }
        })();
      }

      const recentlyViewedSellProps: ProductFeedViewedProperties = {
        feed_placement: "homepage",
        feed_type: "recently_viewed",
        latency: latency,
        tile_type: [
          ...(hasRecentlyViewedHotel
            ? (["recently_viewed_hotel"] as ProductTileType[])
            : []),
          ...(hasRecentlySearchedHotel
            ? (["recently_searched_hotel"] as ProductTileType[])
            : []),
          ...(hasRecentlySearchedFlight
            ? (["recently_searched_air"] as ProductTileType[])
            : []),
          ...(hasRecentlySearchedCar
            ? (["recently_searched_car"] as ProductTileType[])
            : []),
        ],
      };
      trackEvent({
        eventName: PRODUCT_FEED_VIEWED_EVENT,
        properties: {
          ...recentlyViewedSellProps,
        },
      });
    }
  }, [
    recentSearchesAndViews,
    matchesMobile,
    latency,
    recentlyViewedDesktopP0Variant,
    hasRecentlyViewedHotel,
    hasRecentlySearchedHotel,
    hasRecentlySearchedFlight,
    hasRecentlySearchedCar,
  ]);

  const leftScrollButton = () => {
    const overFlowedElement = document.getElementById(
      "recently-viewed-and-searched-cards-wrapper"
    );
    if (overFlowedElement) {
      overFlowedElement.scrollLeft -= 350;
    }
  };
  const rightScrollButton = () => {
    const overFlowedElement = document.getElementById(
      "recently-viewed-and-searched-cards-wrapper"
    );
    if (overFlowedElement) {
      overFlowedElement.scrollLeft += 350;
    }
  };

  const handleOpenPCBenefitsModal = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePCBenefitsModal = () => {
    setAnchorEl(null);
  };

  if (!recentSearchesAndViews.length) {
    return null;
  }

  const renderRecentlySearchedAndViewsCards = () => {
    let recentlySearchedHotelCardCount = 0; // this is used to alternate background image
    let recentlySearchedFlightCardCount = 0;
    let recentlySearchedCarCardCount = 0;
    return recentSearchesAndViews.map(
      (
        recentItem:
          | RecentHotelSearch
          | RecentlyViewedHotel
          | RecentFlightSearch
          | RecentCarSearch,
        index: number
      ) => {
        if ("lodging" in recentItem) {
          !hasRecentlyViewedHotel && setHasRecentlyViewedHotel(true);
          return (
            <div
              ref={ref}
              className="recently-searched-card-view-wrapper recently-viewed"
              key={`recently-viewed-${index}`}
            >
              <RecentlyViewedCard
                recentView={recentItem as RecentlyViewedHotel}
                largestValueAccount={largestValueAccount}
                index={index}
                lodgingSelection={lodgingSelectionsMap[recentItem.location]}
                variant={
                  matchesMobile
                    ? recentlyViewedMobileP0Variant
                    : recentlyViewedDesktopP0Variant
                }
                onPCBannerClick={handleOpenPCBenefitsModal}
              />
            </div>
          );
        } else if (!!(recentItem as RecentHotelSearch).location) {
          recentlySearchedHotelCardCount += 1;
          !hasRecentlySearchedHotel && setHasRecentlySearchedHotel(true);
          return (
            <div
              ref={ref}
              className={clsx(
                "recently-searched-card-view-wrapper recently-searched",
                {
                  "bg-1": recentlySearchedHotelCardCount % 3 === 1,
                  "bg-2": recentlySearchedHotelCardCount % 3 === 2,
                  "bg-3": recentlySearchedHotelCardCount % 3 === 0,
                }
              )}
              key={`recently-searched-${index}`}
            >
              <RecentlySearchedCard
                recentSearch={recentItem as RecentHotelSearch}
                largestValueAccount={largestValueAccount}
                variant={
                  matchesMobile
                    ? recentlyViewedMobileP0Variant
                    : recentlyViewedDesktopP0Variant
                }
                lodgingSelection={
                  lodgingSelectionsMap[
                    (recentItem as RecentHotelSearch).location
                  ]
                }
                uiVariant="hotels"
              />
            </div>
          );
        } else if (!!(recentItem as RecentFlightSearch).departureDate) {
          recentlySearchedFlightCardCount += 1;
          !hasRecentlySearchedFlight && setHasRecentlySearchedFlight(true);
          return (
            <div
              ref={ref}
              className={clsx(
                "recently-searched-card-view-wrapper recently-searched",
                {
                  "bg-1": recentlySearchedFlightCardCount % 2 === 0,
                  "bg-2": recentlySearchedFlightCardCount % 2 === 1,
                }
              )}
              key={`recently-searched-${index}`}
            >
              <RecentlySearchedCard
                recentSearch={recentItem as RecentFlightSearch}
                largestValueAccount={largestValueAccount}
                variant={
                  matchesMobile
                    ? recentlyViewedMobileP0Variant
                    : recentlyViewedDesktopP0Variant
                }
                uiVariant="flights"
              />
            </div>
          );
        } else if (!!(recentItem as RecentCarSearch).pickUpTime) {
          recentlySearchedCarCardCount += 1;
          !hasRecentlySearchedCar && setHasRecentlySearchedCar(true);
          return (
            <div
              ref={ref}
              className={clsx(
                "recently-searched-card-view-wrapper recently-searched",
                {
                  "bg-1": recentlySearchedCarCardCount % 2 === 0,
                  "bg-2": recentlySearchedCarCardCount % 2 === 1,
                }
              )}
              key={`recently-searched-${index}`}
            >
              <RecentlySearchedCard
                recentSearch={recentItem as RecentCarSearch}
                largestValueAccount={largestValueAccount}
                variant={
                  matchesMobile
                    ? recentlyViewedMobileP0Variant
                    : recentlyViewedDesktopP0Variant
                }
                uiVariant="cars"
              />
            </div>
          );
        } else {
          return null;
        }
      }
    );
  };

  return (
    <Box
      className={clsx("recently-viewed-and-searched-root", {
        mobile: matchesMobile,
        "mobile-v1":
          recentlyViewedMobileP0Variant === RECENTLY_VIEWED_MOBILE_P0_V1,
        "mobile-v2":
          recentlyViewedMobileP0Variant === RECENTLY_VIEWED_MOBILE_P0_V2,
        "mobile-v3":
          recentlyViewedMobileP0Variant === RECENTLY_VIEWED_MOBILE_P0_V3,
        "negative-margin": negativeMargin,
      })}
    >
      {isPremierCollectionEnabled && (
        <PremierCollectionBenefitsModal
          handleClosePCBenefitsModal={handleClosePCBenefitsModal}
          anchorEl={anchorEl}
          openBenefitsModal={!!anchorEl}
          largestValueAccount={largestValueAccount}
        />
      )}

      <Box
        className={clsx("title", {
          overflow: isOverFlow,
        })}
      >
        Continue your search
      </Box>
      <Box
        className={clsx("recently-viewed-and-searched-cards-carousel-wrapper", {
          overflow: isOverFlow,
        })}
      >
        {isOverFlow && (
          <BackButton
            onClick={leftScrollButton}
            className="left-scroll-button"
          />
        )}
        <Box
          className="recently-viewed-and-searched-cards-wrapper"
          id="recently-viewed-and-searched-cards-wrapper"
        >
          {renderRecentlySearchedAndViewsCards()}
        </Box>
        {isOverFlow && (
          <BackButton
            onClick={rightScrollButton}
            className="right-scroll-button"
          />
        )}
      </Box>
    </Box>
  );
};
