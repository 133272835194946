import React from "react";
import { IconName, LandingBenefit } from "halifax";
import { LandingPageType } from "./index";
import { CallState, RewardsAccount } from "redmond";
import { Skeleton } from "@material-ui/lab";

export const TITLE_FIRST_HALF_BOLD = "Welcome back ";
export const TITLE_SECOND_HALF = "to travel.";
export const SUBTITLE = "Book the trip you've been waiting for.";
export const PREMIER_COLLECTION_TITLE_FIRST_HALF_BOLD = "Explore luxury hotels";
export const PREMIER_COLLECTION_TITLE_SECOND_HALF = "around the world.";

export const CASH_CARD_BENEFITS_TITLE = (productDisplayName: string) => (
  <>
    <strong>{productDisplayName}</strong> Benefits when you book on Capital One
    Travel
  </>
);

export const CASH_CARD_BENEFITS: { [key: string]: LandingBenefit[] } = {
  VARIANT_A: [
    {
      icon: IconName.Gift,
      text: (
        <>
          Earn <strong>5% cash back</strong> on hotels and rental cars
        </>
      ),
    },
    {
      icon: IconName.PricePredictionIcon,
      text: (
        <>
          <strong>Save an average of 15%</strong> on flights with price
          prediction
        </>
      ),
    },
    {
      icon: IconName.HotelDND,
      text: (
        <>
          We <strong>match hotel prices</strong> before and after you book
        </>
      ),
    },
  ],
  VARIANT_B: [
    {
      icon: IconName.Gift,
      text: (
        <>
          Earn <strong>5% cash back</strong> on hotels and rental cars
        </>
      ),
    },
    {
      icon: IconName.PricePredictionIcon,
      text: (
        <>
          <strong>Save an average of 15%</strong> on flights with price
          prediction
        </>
      ),
    },
    {
      icon: IconName.PriceDropProtectionIcon,
      text: (
        <>
          Book your flight when we recommend and get{" "}
          <strong>price drop protection</strong>
        </>
      ),
    },
  ],
  VARIANT_C: [
    {
      icon: IconName.Gift,
      text: (
        <>
          Earn <strong>5% cash back</strong> on hotels and rental cars
        </>
      ),
    },
    {
      icon: IconName.PricePredictionIcon,
      text: (
        <>
          <strong>Save an average of 15%</strong> on flights with price
          prediction
        </>
      ),
    },
    {
      icon: IconName.BadgeRibbon,
      text: (
        <>
          If you find a better price within 24 hours,{" "}
          <strong>we’ll match it</strong>
        </>
      ),
    },
  ],
};

export const getRedesignVariant3Title = (
  type: LandingPageType,
  fetchRewardsAccountsCallState: CallState,
  largestValueAccount?: RewardsAccount
) => {
  let earnRate: number | undefined = undefined;
  let homesEarnRate: number | undefined = undefined;
  let multiplier: string | undefined = undefined;

  if (
    fetchRewardsAccountsCallState === CallState.Success &&
    largestValueAccount
  ) {
    const { productDisplayName } = largestValueAccount;
    const currency =
      largestValueAccount.rewardsBalance.currencyDescription ??
      largestValueAccount.rewardsBalance.currency;
    multiplier = currency.includes("cash") ? "%" : "X";
    switch (type) {
      case LandingPageType.FLIGHTS:
        earnRate = largestValueAccount.earn.flightsMultiplier;
        break;
      case LandingPageType.HOTELS:
        earnRate = largestValueAccount.earn.hotelsMultiplier;
        break;
      case LandingPageType.STAYS:
        earnRate = largestValueAccount.earn.hotelsMultiplier;
        homesEarnRate = largestValueAccount.earn.hotelsMultiplier; // homes within stays will use the hotels multiplier
        break;
      case LandingPageType.CARS:
        earnRate = largestValueAccount.earn.carsMultiplier;
        break;
      case LandingPageType.EXPERIENCES:
        // TODO - COTA-1548: This should be .earn.experiencesMultiplier but that doesn't yet exist in the BE
        earnRate = largestValueAccount.earn.hotelsMultiplier;
        break;
      default:
        break;
    }

    if (earnRate && homesEarnRate && multiplier) {
      if (earnRate == homesEarnRate) {
        return (
          <>
            <span className="font-regular">
              Earn{" "}
              <strong>
                {earnRate}
                {multiplier} {currency} on stays
              </strong>{" "}
            </span>{" "}
            with your {productDisplayName} account.
          </>
        );
      } else {
        return (
          <>
            <span className="font-regular">
              Earn{" "}
              <strong>
                {earnRate}
                {multiplier} {currency} on hotels
              </strong>
              {` and `}
              <strong>
                {homesEarnRate}
                {multiplier} {currency} on homes
              </strong>
            </span>{" "}
            with your {productDisplayName} account.
          </>
        );
      }
    } else if (earnRate && multiplier) {
      return (
        <span className="font-regular">
          Earn{" "}
          <strong>
            {earnRate}
            {multiplier} {currency}{" "}
          </strong>{" "}
          on {type} with your {productDisplayName} account.
        </span>
      );
    } else if (
      largestValueAccount?.productDisplayName.toLowerCase().includes("paradise")
    ) {
      return (
        <span className="font-regular">Your next journey starts here.</span>
      );
    }
  }

  if (type === LandingPageType.PREMIUM_STAYS) {
    return <>Luxury stays, elevated.</>;
  } else if (
    fetchRewardsAccountsCallState === CallState.Success ||
    fetchRewardsAccountsCallState === CallState.Failed
  ) {
    return (
      <>
        Thousands of <strong>{type}</strong> to choose from.
      </>
    );
  } else {
    return <Skeleton className="title-loading" />;
  }
};

export const getRedesignVariant3Subtitle = (
  type: LandingPageType,
  fetchRewardsAccountsCallState: CallState
) => {
  if (type === LandingPageType.PREMIUM_STAYS) {
    return `Book with our Premier Collection and enjoy extras at one-of-a-kind properties.`;
  } else if (
    fetchRewardsAccountsCallState === CallState.Success ||
    fetchRewardsAccountsCallState === CallState.Failed
  ) {
    return "Book with flexibility and enjoy elevated travel benefits.";
  } else {
    return <Skeleton className="subtitle-loading" />;
  }
};

export const LC_NON_PREMIUM_TITLE = "Vibrant stays, elevated";
export const LC_NON_PREMIUM_SUBTITLE = (
  <>
    Book with our Lifestyle Collection and <br />
    embrace the local scene.
  </>
);

export const LC_PREMIUM_TITLE = "Make your stay extraordinary";
export const LC_PREMIUM_SUBTITLE =
  "Book through the Premier or Lifestyle Collections and enjoy premium benefits.";

export const VR_NON_PREMIUM_SUBTITLE =
  "Book through the Lifestyle Collections and enjoy premium benefits.";

export const PACKAGES_TITLE = (
  <>
    <strong>Unlock special rates</strong> when you book your hotel and flight as
    a package.
  </>
);

export const PACKAGES_SUBTITLE = (rewardsAccount: RewardsAccount) => {
  const earnSymbol =
    rewardsAccount.rewardsBalance.currency === "Cash" ? "%" : "x";

  const { flightsMultiplier, hotelsMultiplier } = rewardsAccount.earn;
  const { currencyDescription } = rewardsAccount.rewardsBalance;

  const flightEarnString = `${flightsMultiplier}${earnSymbol} ${currencyDescription} on flights`;
  const hotelEarnString = `${hotelsMultiplier}${earnSymbol} ${currencyDescription} on hotels`;

  const funnelEarnText = (() => {
    switch (true) {
      case !!flightsMultiplier && !!hotelsMultiplier:
      default:
        return `${flightEarnString} and ${hotelEarnString}`;
      case !!flightsMultiplier:
        return flightEarnString;
      case !!hotelsMultiplier:
        return hotelEarnString;
    }
  })();

  return `Plus earn ${funnelEarnText} with ${rewardsAccount.productDisplayName}.`;
};
