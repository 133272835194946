import { Typography } from "@material-ui/core";
import {
  TravelSaleCardCollection,
  ITravelSaleCardCollectionProps,
  getCountdownValues,
  useDeviceTypes,
  getCountdownText,
  sortTravelSaleDestinations,
  transformToStringifiedHotelAvailabilityQuery,
} from "halifax";
import React from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import {
  CLICKED_SALE_OFFER_CTA,
  ClickedSaleOfferCTATrackingProperties,
  CarEntryTypeEnum,
  FlightEntryTypeEnum,
  Funnel,
  HotelEntryTypeEnum,
  TravelWalletOffer,
  VIEWED_SALE_OFFER_MODAL,
  ViewedSaleOfferModalTrackingProperties,
} from "redmond";
import {
  PATH_CARS,
  PATH_CARS_SEARCH,
  PATH_FLIGHTS,
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS,
  PATH_HOTELS_AVAILABILITY,
  PATH_PREMIER_COLLECTION,
  PATH_TRAVEL_SALE,
} from "../../utils/urlPaths";
import config from "../../utils/config";
import { trackEvent } from "../../api/v1/trackEvent";

const getFunnelTrackingName = (funnel: Funnel) => {
  switch (funnel) {
    case Funnel.Air:
      return "air";
    case Funnel.Lodging:
      return "hotels";
    case Funnel.Ground:
      return "cars";
    case Funnel.Homes:
    case Funnel.Home:
      return "home";
    case Funnel.Experiences:
      return "experiences";
  }
};

interface HomepageTravelSaleProps {
  offers: TravelWalletOffer[];
  active: boolean;
  negativeMargin?: boolean;
}

const getCTARoute = (
  funnel: Funnel,
  offerLocation?: string,
  isMobile?: boolean,
  description?: string
) => {
  switch (funnel) {
    case Funnel.Air:
      return isMobile
        ? `${PATH_FLIGHTS_SEARCH}?entryType=${FlightEntryTypeEnum.TRAVEL_SALE_HOMEPAGE}`
        : PATH_FLIGHTS;
    case Funnel.Lodging:
      if (description && description?.includes("Premier Collection")) {
        return PATH_PREMIER_COLLECTION;
      }
      if (offerLocation) {
        return `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedHotelAvailabilityQuery(
          offerLocation,
          HotelEntryTypeEnum.TRAVEL_SALE_HOMEPAGE
        )}`;
      }
      return PATH_HOTELS;
    case Funnel.Ground:
      return isMobile
        ? `${PATH_CARS_SEARCH}?entryType=${CarEntryTypeEnum.TRAVEL_SALE_HOMEPAGE}`
        : PATH_CARS;
    default:
      return PATH_HOTELS;
  }
};
const sortDestinations = sortTravelSaleDestinations(
  window.__mclean_env__.TRAVEL_SALE_SORTED_DESTINATIONS
);

export const HomepageTravelSale = ({
  offers,
  active,
  negativeMargin = false,
}: HomepageTravelSaleProps) => {
  const history = useHistory();
  const { matchesMobile } = useDeviceTypes();

  const [time, setTimer] = React.useState<
    { [key: string]: number } | undefined
  >(undefined);

  React.useEffect(() => {
    if (!active) {
      const updateTimer = () =>
        setTimer(getCountdownValues(new Date().toISOString()));
      const interval = setInterval(() => {
        updateTimer();
      }, 1000);

      updateTimer();

      return () => clearInterval(interval);
    }
    return () => {};
  }, [active]);

  const groupedOffers = React.useMemo(
    () =>
      offers
        .filter(
          (offer) =>
            config.TRAVEL_SALE_FEATURED_DESTINATIONS.includes(
              offer.descriptions[2]
            ) &&
            offer.funnels[0] !== Funnel.Air &&
            !config.TRAVEL_SALE_SOLD_OUT_HOTEL_DESTINATIONS.includes(
              offer.descriptions[2]
            )
        )
        .reduce((groupedSoFar, currentOffer) => {
          const [, , location] = currentOffer.descriptions;
          const imageId = currentOffer.imageId;

          const existingIndex = groupedSoFar.findIndex((groupedOffer) =>
            groupedOffer.applicableOffers.some(
              (offer) => offer.offer.descriptions[2] === location
            )
          );

          if (existingIndex > -1) {
            groupedSoFar[existingIndex].applicableOffers.unshift({
              offer: currentOffer,
              onClickCTA: () => {
                history.push(
                  getCTARoute(
                    currentOffer.funnels[0],
                    currentOffer.descriptions[2],
                    matchesMobile,
                    currentOffer.descriptions[0]
                  )
                );
                const properties: ClickedSaleOfferCTATrackingProperties = {
                  location_brand: currentOffer.descriptions[2],
                  funnel: getFunnelTrackingName(currentOffer.funnels[0]),
                };
                trackEvent({
                  eventName: CLICKED_SALE_OFFER_CTA,
                  properties,
                });
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 400);
              },
            });
          } else {
            const imagePath = imageId ?? location;
            groupedSoFar.push({
              image: `${window.location.origin}/email-assets/offer-images/${imagePath}/750x300.jpg`,
              applicableOffers: [
                {
                  offer: currentOffer,
                  onClickCTA: () => {
                    history.push(
                      getCTARoute(
                        currentOffer.funnels[0],
                        currentOffer.descriptions[2],
                        matchesMobile,
                        currentOffer.descriptions[0]
                      )
                    );
                    const properties: ClickedSaleOfferCTATrackingProperties = {
                      location_brand: currentOffer.descriptions[2],
                      funnel: getFunnelTrackingName(currentOffer.funnels[0]),
                    };
                    trackEvent({
                      eventName: CLICKED_SALE_OFFER_CTA,
                      properties,
                    });
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 400);
                  },
                },
              ],
            });
          }

          return groupedSoFar;
        }, [] as ITravelSaleCardCollectionProps["groupedOffers"])
        .sort((a, b) =>
          sortDestinations(
            a.applicableOffers[0].offer.descriptions[2],
            b.applicableOffers[0].offer.descriptions[2]
          )
        ),
    [offers, history]
  );

  if (!groupedOffers.length) return null;

  return (
    <>
      {!matchesMobile && (
        <Typography variant="h3" className="homepage-travel-sale-heading">
          {active ? (
            "Our first-ever travel sale has landed"
          ) : (
            <>
              Our first-ever travel sale lands in{" "}
              <strong>{getCountdownText(time)}</strong>
            </>
          )}
        </Typography>
      )}
      <TravelSaleCardCollection
        groupedOffers={groupedOffers}
        onClickCTA={() => {
          const path = `${PATH_TRAVEL_SALE}?entryType=search_carousel`;
          matchesMobile ? history.push(path) : window.open(path, "_blank");
        }}
        cardVariant="featured"
        active={active}
        isMobile={matchesMobile}
        className={negativeMargin ? "negative-margin" : undefined}
        onOpenModal={(selectedOffer) =>
          trackEvent({
            eventName: VIEWED_SALE_OFFER_MODAL,
            properties: {
              funnel: getFunnelTrackingName(selectedOffer.funnels[0]),
              location_brand: selectedOffer.descriptions[2],
            } as ViewedSaleOfferModalTrackingProperties,
          })
        }
      />
    </>
  );
};
