import axios from "axios";
import { config } from "../../config";
import { crossSellActiveOffers } from "../../paths";
import { ActiveCrossSellOffersResponse } from "redmond";

export const fetchCrossSellActiveOffers =
  async (_: {}): Promise<ActiveCrossSellOffersResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${crossSellActiveOffers}`, {
          baseURL: config.baseURL,
        });
        resolve(res.data as ActiveCrossSellOffersResponse);
      } catch (e) {
        reject(e);
      }
    });
  };
