import axios from "axios";
import {
  NETWORK_CALL_FAILED,
  CityNameRequest,
  CityNameResponse,
} from "redmond";
import { config } from "../../config";
import { trackEvent } from "../trackEvent";
import { cityNameApiPrefix } from "../../paths";

export const getCityName = (body: CityNameRequest): Promise<CityNameResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(cityNameApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      trackEvent({
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      });
      reject(e);
    }
  });
