import axios from "axios";
import { RecentlyViewedHotelResponse } from "redmond";
import { config } from "../../config";
import { recentlyViewedApiPrefix } from "../../paths";
const END_POINT: string = `${recentlyViewedApiPrefix}/hotels`;

export const fetchRecentlyViewedHotels =
  (): Promise<RecentlyViewedHotelResponse> =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(END_POINT, {
          baseURL: config.baseURL,
        });
        resolve(res.data);
      } catch (e) {
        reject(e);
      }
    });
