import React, { useEffect, useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core";
import { ExperimentState, ExperiencesModuleProps } from "redmond";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";

import CapOneLogo from "../../assets/b2b/capone-logo.svg";
import { PAWTUCKET_EXPERIENCES_MODULE_ID } from "../../utils/moduleIds";
import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { colors } from "../../utils/capone/colors";
import clsx from "clsx";
import { useUserContext } from "@capone/common";
import { CAP_ONE_LOGO_ALT } from "../../utils/constants";
import {
  CONTROL,
  getExperimentVariant,
  EXPERIENCES_EXPERIMENT,
  useExperiments,
} from "../../context/experiments";
import { PATH_HOTELS } from "../../utils/urlPaths";
import "./styles.scss";

interface IExperiencesModuleProps extends RouteComponentProps {
  language: string;
  experiments?: ExperimentState;
}

const ExperiencesModule = (props: IExperiencesModuleProps) => {
  const { language, experiments, ...routerProps } = props;
  const { history } = routerProps;
  const id = PAWTUCKET_EXPERIENCES_MODULE_ID;
  const theme = useTheme();
  const { matchesDesktop, matchesSmallDesktop } = useDeviceTypes();
  const { isLoaded, ptExperiencesModule } = useMicrofrontend(
    id,
    (config.ptExperiencesModuleUrl += "")
  );
  const { sessionInfo } = useUserContext(config.TENANT);

  const expState = useExperiments();
  const isExperiencesExperiment =
    getExperimentVariant(expState.experiments, EXPERIENCES_EXPERIMENT) !==
    CONTROL;

  useEffect(() => {
    if (experiments?.experiments.length && !isExperiencesExperiment) {
      history.push(PATH_HOTELS);
    }
  }, [experiments]);

  useLayoutEffect(() => {
    if (!ptExperiencesModule) return;
    const { render, unMount } = ptExperiencesModule;
    const ExperiencesModuleProps: ExperiencesModuleProps = {
      theme,
      language: language,
      colors: colors,
      baseHistory: history,
      isAgentPortal: config.ptIsAgentPortal,
      clientAssets: {
        logo: <img src={CapOneLogo} alt={CAP_ONE_LOGO_ALT} />,
        sessionInfo,
      },
      experiments,
    };

    render(id, ExperiencesModuleProps);

    return () => unMount(id);
  }, [isLoaded]); // eslint-disable-line

  if (!isLoaded) return null;

  if (!ptExperiencesModule) return <h2>ERROR! Cannot find Module</h2>;

  return (
    <div
      className={clsx("experiences-module-container", {
        desktop: matchesDesktop || matchesSmallDesktop,
      })}
      id={id}
    />
  );
};

export default ExperiencesModule;
