import axios from "axios";

import { config } from "../../config";
import { cotbApiPrefix } from "../../paths";
import { CorpBusinessAccountResponse } from "redmond/apis/tysons/businesses";

const END_POINT: string = `${cotbApiPrefix}/listBusinessAccounts`;

export const fetchBusinessAccounts = (): Promise<CorpBusinessAccountResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        END_POINT,
        {},
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
