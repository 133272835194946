import axios from "axios";
import { config } from "../../config";
import { recentlySearchedApiPrefix } from "../../paths";
import { RecentlySearchedFlightsResponse } from "redmond";

const END_POINT: string = `${recentlySearchedApiPrefix}/flights`;

export const fetchRecentlySearchedFlights =
  (): Promise<RecentlySearchedFlightsResponse> =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(END_POINT, {
          baseURL: config.baseURL,
        });
        resolve(res.data);
      } catch (e) {
        reject(e);
      }
    });
